import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { File } from '@ionic-native/file/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage';
import { AppLauncher } from '@ionic-native/app-launcher/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Camera } from '@ionic-native/camera/ngx';
import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';

import {
  FileTransfer,
  FileUploadOptions,
  FileTransferObject
} from '@ionic-native/file-transfer/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { IonicSelectableModule } from 'ionic-selectable';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
//import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { Congratulations2Component } from './congratulations2/congratulations2.component';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { UnapprovedPostPipe } from './unapproved-post.pipe';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { Media } from '@ionic-native/media/ngx';
import { defineCustomElements } from '@teamhive/lottie-player/loader';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { AnimationModalPageModule } from './lms/section-details/animation-modal/animation-modal/animation-modal.module';

@NgModule({
  declarations: [AppComponent, Congratulations2Component, UnapprovedPostPipe,],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({mode: 'md'}),
    AppRoutingModule,
    HttpClientModule,
    FormsModule, 
    IonicStorageModule.forRoot({driverOrder: ['localstorage', 'indexeddb', 'websql','sqlite']}),
    NgxIonicImageViewerModule,
    IonicSelectableModule,
    AnimationModalPageModule,
    NgCircleProgressModule.forRoot({
      backgroundStrokeWidth: 0,
      backgroundPadding: 7,
      space: -3,
      toFixed: 0, 
      outerStrokeWidth: 4,
      outerStrokeColor: '#808080',
      innerStrokeWidth: 2,
      innerStrokeColor: '#e7e8ea',
      animationDuration: 500,
      animation: true,
      startFromZero: false,
      responsive: true,
      showUnits: false,
      showTitle: true,
      showSubtitle: false,
      showImage: false,
      renderOnClick: false
    })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Camera,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    File,
    SQLite,
    FCM,
    AppLauncher,
    DatePipe,
    FileChooser,
    FileTransfer,
    FilePath,
    GoogleAnalytics,
    InAppBrowser,
    Base64ToGallery,
    AndroidPermissions,
    GooglePlus,
    Facebook,
    MediaCapture,
    Media ,   
    SocialSharing,
    Deeplinks,
    FirebaseDynamicLinks,
    Downloader,
    TextToSpeech,
  
  
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
