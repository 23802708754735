import { Component, OnInit } from '@angular/core';
import { Platform, ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-congratulations2',
  templateUrl: './congratulations2.component.html',
  styleUrls: ['./congratulations2.component.scss'],
})
export class Congratulations2Component implements OnInit {
  backButtonSubscription;
  totalPoint: number = 0;
  flag: boolean;
  lang: any;
  curretnQuize: any;
  markedAns: any;
  constructor(
    private platform: Platform,
    private router: Router,
    private sharedPreference: Storage,
    private modalController: ModalController,
    private navParams: NavParams
  ) {
    
    console.table(this.navParams);
    this.curretnQuize = this.navParams.data.quizDetail;
    this.totalPoint = this.navParams.data.total;
    this.markedAns = this.navParams.data.markedAns;
    this.flag = this.navParams.data.flag;
  }

  ngOnInit() { }
  ionViewWillEnter() {
    this.sharedPreference.get('language').then((lang) => {
      this.sharedPreference.get(lang).then((l) => {

        this.lang = l.congratulations;
      });
    });
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(
      9999,
      () => { }
    );
  }

  ionViewWillLeave() {
    this.backButtonSubscription.unsubscribe();
  }


  quizres() {
    this.closeModal();
    this.sharedPreference.set('quizresult-sharedPref', JSON.stringify({
      quizDetail: this.curretnQuize,
      total: this.totalPoint,
      markedAns: this.markedAns,
      flag: this.flag
    }));
    this.router.navigateByUrl('/quizresult');
  }
  quizlist() {
    this.closeModal();
    this.router.navigateByUrl('/quizlist');
  }
  async closeModal() {
    //const onClosedData: string = "Wrapped Up!";
    await this.modalController.dismiss();
  }

}
