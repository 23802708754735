import { TeachersData } from './TeachersData';

export class Login {
  RpgId: number = 0;
  Mobile: string;
  Password: string;
  Name: string;
  SessionToken: '';
  DeviceId: string;
  SchoolId: number = 0;
  LocationId: number = 0;
  WAGroup: string;
  PushNotificationId: number = 0;
  Role: string;
  PasswordValidTill: string;
  PassValidFlag: number = 0;
  CreatedBy: string;
  CreatedOn: string;
  UpdatedBy: string;
  UpdatedOn: string;
  isActive: number = 0;
  isFirstLogin: number = 0;
  isSessionExpired: number = 0;
  SchoolName: string;
  LocationName: string;
  teacher: any;
  loginType: string;
  level:string
  standard:string

  constructor() {}
}
