import { Injectable } from '@angular/core';
import { Platform, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { SQLite, SQLiteObject } from '@ionic-native/sqlite/ngx';
import { Storage } from '@ionic/storage';
import { UserResponse } from '../models/UserResponse';
import { Resource } from '../models/Resource';
import { Question } from '../models/Question';
import { ContestDetail } from '../models/ContestDetail';
import { Article } from '../models/Article';
import { TeachersData } from '../models/TeachersData';
import { SchoolData } from '../models/SchoolData';
import { Location } from '../models/Location';
import { QuizeDetail } from '../models/QuizDetail';
import { DatePipe } from '@angular/common';
import { Feedback } from '../models/Feedback';
import { SessionTrainingDetails } from '../models/SessionTrainingDetails';
import { CategoryDetails } from '../models/CategoryDetails';
import { FacilitatorCohortsMapping } from '../models/FacilitatorCohortsMapping';
import { ScheduleDetails } from '../models/ScheduleDetails';
import { Login } from '../models/Login';
import { SaveAttendanceDetails } from '../models/SaveAttendanceDetails';
import { Leaderboard } from '../models/Leaderboard';
import { MyPoints } from '../models/MyPoints';
import { ByLine } from '../models/ByLine';
import { ActivityDetails } from '../models/ActivityDetails';
import { RoleTypeMapping } from '../models/RoleTypeMapping';
import { CoordinatorCohortsMapping } from '../models/CoordinatorCohortsMapping';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseHelperService {
  DatabaseName = 'PehlayAkshar.db';
  DB: SQLiteObject = null;
  version = 1;
  createTableQueryObject = {
    articles:
      'create TABLE IF NOT EXISTS articles (ArticleId INTEGER,ResourceId INTEGER,Image TEXT,Description TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
    contestdetails:
      'create TABLE IF NOT EXISTS contestdetails (ContestId INTEGER,ContestText TEXT,EvaluationCriterias TEXT,ContestStartDate TEXT,ContestEndDate TEXT,EvaluationStatus TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
    question:
      'create TABLE IF NOT EXISTS question (QuestionId INTEGER,QuizId INTEGER,SessionTrainingMasterId INTEGER,QuestionType TEXT,QuestionText TEXT,Options TEXT,Answer TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
    quizdetails:
      'create TABLE IF NOT EXISTS quizdetails (QuizId INTEGER,QuizName TEXT,isActive INTEGER,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
    resource:
      'create TABLE IF NOT EXISTS resource (ResourceId INTEGER,ResourceType TEXT,Header TEXT,ResourceLink TEXT,ResourceDescription TEXT,TargetAudienceType TEXT,Category TEXT,SubCategory TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
    feedback:
      'create TABLE IF NOT EXISTS feedback (FeedBackId INTEGER,SessionTrainingMasterId INTEGER,StartDate TEXT,EndDate TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT,SessionTrainingType TEXT,SessionTrainingName TEXT)',
    userresponses:
      'create TABLE IF NOT EXISTS userresponses (Id INTEGER,RpgId INTEGER,Type TEXT,DataLink TEXT,ContestGrade TEXT,QuizId INTEGER,ContestId INTEGER,OthersId INTEGER,AnswerJson TEXT,QuizPoints TEXT,ContestPoints TEXT,OthersPoints TEXT,TotalPoints TEXT,QuizTakenDate TEXT,ContestTakenDate TEXT,ViewedDate TEXT,CreatedBy TEXT,CreatedOn TEXT,IsSynced INTEGER,SessionTrainingDate TEXT,FeedBackDate TEXT)',
    categorydetails:
      'create TABLE IF NOT EXISTS categorydetails (CategoryId INTEGER, AdminId INTEGER, ResourceType TEXT, TargetAudienceType TEXT, Category TEXT, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    FacilitatorCohortsMapping:
      'create TABLE IF NOT EXISTS FacilitatorCohortsMapping (Id INTEGER, AdminId INTEGER, FacilitatoriId INTEGER, CohortsId INTEGER, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    ScheduleDetails:
      'create TABLE IF NOT EXISTS ScheduleDetails (ScheduleId INTEGER, AdminId INTEGER, SessionTrainingMasterId INTEGER, FacilitatorId INTEGER, SessionTrainingName TEXT, PlannedDate TEXT, Day TEXT, Cohort TEXT, DecidedLocation TEXT, PlannedFromTime TEXT, PlannedToTime TEXT, ActualDate TEXT, ActualFromTime TEXT, ActualToTime TEXT, Status TEXT, Role TEXT, Ward TEXT, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    teachersData:
      'create TABLE IF NOT EXISTS teachersData (RpgId INTEGER, Cohort TEXT, Name TEXT)',
    saveAttendanceDetailsTeacherAttendance:
      'create TABLE IF NOT EXISTS saveAttendanceDetailsTeacherAttendance (SessionTrainingMasterId INTEGER, Cohort TEXT, teacherAttendanceJson TEXT, CreatedOn TEXT)',
    leaderboard:
      'create TABLE IF NOT EXISTS leaderboard (Id INTEGER, RpgId INTEGER, Name TEXT, Cohort TEXT, Ward TEXT, TotalPoints INTEGER, ResponseDate TEXT, Month TEXT, Rank INTEGER, Role TEXT, Location TEXT, OldRank INTEGER, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    MyPoints:
      'create TABLE IF NOT EXISTS MyPoints (RpgId INTEGER, Name TEXT, typeWisePointsList TEXT, LeaderBoardPoints INTEGER, TotalPoints INTEGER, Rank INTEGER, UpdatedOn TEXT)',
    ByLine:
      'create TABLE IF NOT EXISTS ByLine (Id INTEGER,AdminId INTEGER,ResourceType TEXT, TargetAudienceType TEXT,ByLineForResType TEXT,CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    ActivityDetails:
      'create TABLE IF NOT EXISTS ActivityDetails (Id INTEGER,ResourceId INTEGER,ActivityType TEXT, Description TEXT,AdminId INTEGER,Resource TEXT,CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
    RoleTypeMapping:
      'create TABLE IF NOT EXISTS RoleTypeMapping (Id INTEGER,AdminId INTEGER,RoleType TEXT, Role TEXT)',
    CoordinatorCohortsMapping:
      'create TABLE IF NOT EXISTS CoordinatorCohortsMapping (Id INTEGER, AdminId INTEGER, CoordinatorId INTEGER, Cohort INTEGER, CoordinatorName TEXT, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
     
  };

  constructor(
    public platform: Platform,
    public sqlite: SQLite,
    public datepipe: DatePipe,
    private router: Router,
    public loadingController: LoadingController,
    public sharedPrefrence: Storage,
    public api:UserService
  ) {
    this.createOrOpenDB();
  }

  createOrOpenDB() {
    this.platform.ready().then(() => {
      this.sqlite
        .create({
          name: this.DatabaseName,
          location: 'default'
        })
        .then((db: SQLiteObject) => {
          this.DB = db;
        });
    });
  }

  createTables() {
    return new Promise((resolve, reject) => {
      // , IsSynced INTEGER

      // this.sqlite
      //     .create({
      //       name: this.DatabaseName,
      //       location: 'default'
      //     })
      //     .then((db: SQLiteObject) => {
      //       db.executeSql(
      // tslint:disable: max-line-length
      //         "create TABLE IF NOT EXISTS location (LocationId INTEGER,LocationName TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)"
      //       ).then(d => {}, e => {});
      //     });

      // this.sqlite
      //     .create({
      //       name: this.DatabaseName,
      //       location: 'default'
      //     })
      //     .then((db: SQLiteObject) => {
      //       db.executeSql(
      // tslint:disable: max-line-length
      //         "create TABLE IF NOT EXISTS schooldata (SchoolId INTEGER,UdiseNo TEXT,SchoolName TEXT,ShortName TEXT,AddressLine1 TEXT,AddressLine2 TEXT,Pincode TEXT,"+
      // "LocationId INTEGER,GradesTaught TEXT,Medium TEXT,NoOfStudents TEXT,NoOfTeachers TEXT,HeadMaster TEXT,SchoolTime TEXT,CreatedBy TEXT,CreatedOn TEXT,"+
      // "UpdatedBy TEXT,UpdatedOn TEXT)"
      //       ).then(d => {}, e => {});
      //     });

      // this.sqlite
      //     .create({
      //       name: this.DatabaseName,
      //       location: 'default'
      //     })
      //     .then((db: SQLiteObject) => {
      //       db.executeSql(
      // tslint:disable: max-line-length
      //         "create TABLE IF NOT EXISTS teachersdata (RpgId INTEGER,Dob TEXT,Email TEXT,Gender TEXT,UdiseNo TEXT,ClassesTaught TEXT,SubjectsTaught TEXT,HighestDegree TEXT,"+
      // "YearsOfExp TEXT,CreatedBy TEXT,CreatedOn TEXT,UpadtedBy TEXT,UpadtedOn TEXT)"
      //       ).then(d => {}, e => {});
      //     });


      // tslint:disable: max-line-length
      this.sqlite
        .create({
          name: this.DatabaseName,
          location: 'default'
        })
        .then(
          (db: SQLiteObject) => {
            this.DB = db;
            db.executeSql(
              'create TABLE IF NOT EXISTS articles (ArticleId INTEGER,ResourceId INTEGER,Image TEXT,Description TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
              []
            ).then(
              () => {
                this.DB.executeSql(
                  'create TABLE IF NOT EXISTS contestdetails (ContestId INTEGER,ContestText TEXT,EvaluationCriterias TEXT,ContestStartDate TEXT,ContestEndDate TEXT,EvaluationStatus TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
                  []
                ).then(
                  () => {
                    this.DB.executeSql(
                      'create TABLE IF NOT EXISTS question (QuestionId INTEGER,QuizId INTEGER,SessionTrainingMasterId INTEGER,QuestionType TEXT,QuestionText TEXT,Options TEXT,Answer TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
                      []
                    ).then(
                      () => {
                        this.DB.executeSql(
                          'create TABLE IF NOT EXISTS quizdetails (QuizId INTEGER,QuizName TEXT,isActive INTEGER,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
                          []
                        ).then(
                          () => {
                            this.DB.executeSql(
                              'create TABLE IF NOT EXISTS resource (ResourceId INTEGER,ResourceType TEXT,Header TEXT,ResourceLink TEXT,ResourceDescription TEXT,TargetAudienceType TEXT,Category TEXT,SubCategory TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT)',
                              []
                            ).then(
                              () => {
                                this.DB.executeSql(
                                  'create TABLE IF NOT EXISTS feedback (FeedBackId INTEGER,SessionTrainingMasterId INTEGER,StartDate TEXT,EndDate TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT,SessionTrainingType TEXT,SessionTrainingName TEXT)',
                                  []
                                ).then(
                                  () => {
                                    this.DB.executeSql(
                                      'create TABLE IF NOT EXISTS userresponses (Id INTEGER,RpgId INTEGER,Type TEXT,DataLink TEXT,ContestGrade TEXT,QuizId INTEGER,ContestId INTEGER,OthersId INTEGER,AnswerJson TEXT,QuizPoints TEXT,ContestPoints TEXT,' +
                                      'OthersPoints TEXT,TotalPoints TEXT,QuizTakenDate TEXT,ContestTakenDate TEXT,ViewedDate TEXT,CreatedBy TEXT,CreatedOn TEXT,IsSynced INTEGER,SessionTrainingDate TEXT,FeedBackDate TEXT)',
                                      []
                                    ).then(
                                      () => {
                                        this.DB.executeSql(
                                          'create TABLE IF NOT EXISTS categorydetails (CategoryId INTEGER, AdminId INTEGER, ResourceType TEXT, TargetAudienceType TEXT, Category TEXT, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
                                          []
                                        ).then(
                                          () => {
                                            this.DB.executeSql(
                                              'create TABLE IF NOT EXISTS FacilitatorCohortsMapping (Id INTEGER, AdminId INTEGER, FacilitatoriId INTEGER, CohortsId INTEGER, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
                                              []
                                            ).then(
                                              () => {
                                                this.DB.executeSql(
                                                  'create TABLE IF NOT EXISTS ScheduleDetails (ScheduleId INTEGER, AdminId INTEGER, SessionTrainingMasterId INTEGER, FacilitatorId INTEGER, SessionTrainingName TEXT, PlannedDate TEXT, Day TEXT, Cohort TEXT, DecidedLocation TEXT, PlannedFromTime TEXT, PlannedToTime TEXT, ActualDate TEXT, ActualFromTime TEXT, ActualToTime TEXT, Status TEXT, Role TEXT, Ward TEXT, CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
                                                  []
                                                ).then(
                                                  () => {
                                                    this.DB.executeSql(
                                                      'create TABLE IF NOT EXISTS teachersData (RpgId INTEGER, Cohort TEXT, Name TEXT)',
                                                      []
                                                    ).then(
                                                      () => {
                                                        this.DB.executeSql(
                                                          'create TABLE IF NOT EXISTS ByLine (Id INTEGER,AdminId INTEGER,ResourceType,TEXT, TargetAudienceType TEXT,ByLineForResType TEXT,CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)',
                                                          []
                                                        ).then(
                                                          () => {
                                                            this.DB.executeSql(
                                                              'create TABLE IF NOT EXISTS saveAttendanceDetailsTeacherAttendance (SessionTrainingMasterId INTEGER, Cohort TEXT, teacherAttendanceJson TEXT, CreatedOn TEXT)',
                                                              []
                                                            ).then(
                                                              () => {
                                                                resolve(true);
                                                          
                                                              },
                                                              (err) => {
                                                                console.log(err);
                                                                reject(
                                                                  'Error creating saveAttendanceDetailsTeacherAttendance table.'
                                                                );
                                                              }
                                                            );
                                                          },
                                                          (err) => {
                                                            console.log(err);
                                                            reject(
                                                              'Error creating ByLine table.'
                                                            );
                                                          }
                                                        );
                                                      },
                                                      (err) => {
                                                        console.log(err);
                                                        reject(
                                                          'Error creating teachersData table.'
                                                        );
                                                      }
                                                    );
                                                  },
                                                  (err) => {
                                                    console.log(err);
                                                    reject(
                                                      'Error creating ScheduleDetails table.'
                                                    );
                                                  }
                                                );
                                              },
                                              (err) => {
                                                console.log(err);
                                                reject(
                                                  'Error creating FacilitatorCohortsMapping table.'
                                                );
                                              }
                                            );
                                          },
                                          (err) => {
                                            console.log(err);
                                            reject(
                                              'Error creating categorydetails table.'
                                            );
                                          }
                                        );
                                      },
                                      (err) => {
                                        console.log(err);
                                        reject(
                                          'Error creating userresponses table.'
                                        );
                                      }
                                    );
                                  },
                                  (err) => {
                                    console.log(err);
                                    reject('Error creating feedback table.');
                                  }
                                );
                              },
                              (err) => {
                                console.log(err);
                                reject('Error creating resource table.');
                              }
                            );
                          },
                          (err) => {
                            console.log(err);
                            reject('Error creating quizdetails table.');
                          }
                        );
                      },
                      (err) => {
                        console.log(err);
                        reject('Error creating question table.');
                      }
                    );
                  },
                  (err) => {
                    console.log(err);
                    reject('Error creating contestdetails table.');
                  }
                );
              },
              (err) => {
                console.log(err);
                reject('Error creating articles table.');
              }
            );
          },
          (e) => {
            reject('Error opening database.');
          }
        );
    });
  }

  insertCoordinatorCohortsMapping(
    facCohList: Array<CoordinatorCohortsMapping>
  ) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      let facCohInsertList = [];
      facCohList.forEach((element) => {
        facCohInsertList.push([
          'INSERT INTO CoordinatorCohortsMapping  (Id , AdminId , CoordinatorId , Cohort , CoordinatorName, CreatedBy , CreatedOn , UpdatedBy , UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?)',
          [
            element.Id,
            element.AdminId,
            element.CoordinatorId,
            element.Cohort,
            element.CoordinatorName,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(facCohInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertRoleTypeMapping(
    ResList: Array<RoleTypeMapping>
  ) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      let insertList = [];
      ResList.forEach((element) => {
        insertList.push([
          'INSERT INTO RoleTypeMapping  (Id , AdminId , RoleType , Role ) VALUES (?,?,?,?)',
          [
            element.Id,
            element.AdminId,
            element.RoleType,
            element.Role
          ]
        ]);
      });
      this.DB.sqlBatch(insertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertActivityDetails(ActivityDetailsList: Array<ActivityDetails>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let ActivityDetailsInsertList = [];
      if (ActivityDetailsList !== null && ActivityDetailsList !== undefined && ActivityDetailsList.length !== 0) {
        ActivityDetailsList.forEach((element) => {
          ActivityDetailsInsertList.push([
            'INSERT INTO ActivityDetails (Id,ResourceId,ActivityType, Description,AdminId,Resource,CreatedBy, CreatedOn, UpdatedBy, UpdatedOn) VALUES (?,?,?,?,?,?,?,?,?,?)',
            [
              element.Id,
              element.ResourceId,
              element.ActivityType,
              element.Description,
              element.AdminId,
              JSON.stringify(element.Resource),
              element.CreatedBy,
              element.CreatedOn,
              element.UpdatedBy,
              element.UpdatedOn
            ]
          ]);
        });
        this.DB.sqlBatch(ActivityDetailsInsertList).then(
          () => {
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  insertMyPointsDetails(MyPointsList: MyPoints) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let MyPointsInsertList = [];
      if (MyPointsList !== null && MyPointsList !== undefined) {
        MyPointsInsertList.push([
          'INSERT INTO MyPoints (RpgId, Name, typeWisePointsList, LeaderBoardPoints, TotalPoints, Rank, UpdatedOn) VALUES (?,?,?,?,?,?,?)',
          [
            MyPointsList.RpgId,
            MyPointsList.Name,
            JSON.stringify(MyPointsList.typeWisePointsList),
            MyPointsList.LeaderBoardPoints,
            MyPointsList.TotalPoints,
            MyPointsList.Rank,
            MyPointsList.UpdatedOn
          ]
        ]);

        this.DB.sqlBatch(MyPointsInsertList).then(
          () => {
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  insertLeaderboardDetails(leaderboardList: Array<Leaderboard>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let leaderboardInsertList = [];
      if (leaderboardList.length !== 0) {
        leaderboardList.forEach((element) => {
          leaderboardInsertList.push([
            'INSERT INTO leaderboard  (Id, RpgId, Name, Cohort, Ward, TotalPoints, ResponseDate, Month, Rank, Role, Location, OldRank, CreatedBy, CreatedOn, UpdatedBy, UpdatedOn) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
            [
              element.Id,
              element.RpgId,
              element.Name,
              element.Cohort,
              element.Ward,
              element.TotalPoints,
              element.ResponseDate,
              element.Month,
              element.Rank,
              element.Role,
              element.Location,
              element.OldRank,
              element.CreatedBy,
              element.CreatedOn,
              element.UpdatedBy,
              element.UpdatedOn
            ]
          ]);
        });
        this.DB.sqlBatch(leaderboardInsertList).then(
          () => {
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  insertTeacherAttendance(teacher) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      this.DB.executeSql(
        'INSERT INTO saveAttendanceDetailsTeacherAttendance  (SessionTrainingMasterId, Cohort, teacherAttendanceJson, CreatedOn) VALUES (?,?,?,?)',
        [
          teacher.SessionTrainingMasterId,
          teacher.Cohort,
          teacher.teacherAttendanceJson,
          teacher.CreatedOn
        ]
      ).then(
        (data) => { },
        (error) => {
          // alert('Report Error inserting location content : ' + error);
          this.sharedPrefrence.get('language').then((lang) => {
            this.sharedPrefrence.get(lang).then((l) => {
              alert(
                l.databaseHelper_service.report_error +
                'inserting saveAttendanceDetailsTeacherAttendance content : ' +
                error
              );
            });
          });
          // alert('ERROR: ' + JSON.stringify(error.err));
        }
      );
    }
  }

  insertTeachersDataForFacilitator(teachersList) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let teachersInsertList = [];
      if (teachersList.length !== 0) {
        teachersList.forEach((element) => {
          teachersInsertList.push([
            'INSERT INTO teachersData  (RpgId, Cohort, Name) VALUES (?,?,?)',
            [element.RpgId, element.teacher.Cohort, element.Name]
          ]);
        });
        this.DB.sqlBatch(teachersInsertList).then(
          () => {
            resolve(true);
          },
          (err) => {
            reject(err);
          }
        );
      } else {
        resolve(true);
      }
    });
  }

  insertFacilitatorCohortsMapping(
    facCohList: Array<FacilitatorCohortsMapping>
  ) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      let facCohInsertList = [];
      facCohList.forEach((element) => {
        facCohInsertList.push([
          'INSERT INTO FacilitatorCohortsMapping  (Id , AdminId , FacilitatoriId , CohortsId , CreatedBy , CreatedOn , UpdatedBy , UpdatedOn ) VALUES (?,?,?,?,?,?,?,?)',
          [
            element.Id,
            element.AdminId,
            element.FacilitatoriId,
            element.CohortsId,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(facCohInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertScheduleDetails(scheduleDetailsList: Array<ScheduleDetails>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      var scheduleDetailsInsertList = [];
      scheduleDetailsList.forEach((element) => {
        scheduleDetailsInsertList.push([
          'INSERT INTO ScheduleDetails (ScheduleId, AdminId, SessionTrainingMasterId, FacilitatorId, SessionTrainingName,PlannedDate, Day, Cohort,' +
          'DecidedLocation, PlannedFromTime, PlannedToTime, ActualDate, ActualFromTime, ActualToTime, Status, Role, Ward, CreatedBy, CreatedOn, UpdatedBy, UpdatedOn) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
          [
            element.ScheduleId,
            element.AdminId,
            element.SessionTrainingMasterId,
            element.FacilitatorId,
            element.SessionTrainingName,
            element.PlannedDate,
            element.Day,
            element.Cohort,
            element.DecidedLocation,
            element.PlannedFromTime,
            element.PlannedToTime,
            element.ActualDate,
            element.ActualFromTime,
            element.ActualToTime,
            element.Status,
            element.Role,
            element.Ward,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(scheduleDetailsInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertByLineDetails(SessTrainDetsList: Array<ByLine>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let SessTrainDetsInsertList = [];
      SessTrainDetsList.forEach((element) => {
        //'create TABLE IF NOT EXISTS ByLine (Id INTEGER,AdminId INTEGER,ResourceType,TEXT, TargetAudienceType TEXT,ByLineForResType TEXT,CreatedBy TEXT, CreatedOn TEXT, UpdatedBy TEXT, UpdatedOn TEXT)'
        SessTrainDetsInsertList.push([
          'INSERT INTO ByLine (Id, AdminId, ResourceType, TargetAudienceType, ByLineForResType, CreatedBy, CreatedOn, UpdatedBy, UpdatedOn) VALUES (?,?,?,?,?,?,?,?,?)',
          [
            element.Id,
            element.AdminId,
            element.ResourceType,
            element.TargetAudienceType,
            element.ByLineForResType,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(SessTrainDetsInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertCategoryDetails(SessTrainDetsList: Array<CategoryDetails>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let SessTrainDetsInsertList = [];
      SessTrainDetsList.forEach((element) => {
        SessTrainDetsInsertList.push([
          'INSERT INTO categorydetails (CategoryId, AdminId, ResourceType, TargetAudienceType, Category, CreatedBy, CreatedOn, UpdatedBy, UpdatedOn) VALUES (?,?,?,?,?,?,?,?,?)',
          [
            element.CategoryId,
            element.AdminId,
            element.ResourceType,
            element.TargetAudienceType,
            element.Category,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(SessTrainDetsInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertSessionTrainingDetails(
    SessTrainDetsList: Array<SessionTrainingDetails>
  ) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      SessTrainDetsList.forEach((element) => {
        this.DB.executeSql(
          'INSERT INTO sessiontrainingdetails (Id ,Location ,StartDate ,EndDate,SessionTrainingMasterId,feedBackDetails,SessionTrainingName,SessionTrainingType, YearsOfExpWithRPG ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?)',
          [
            element.Id,
            element.Location,
            element.StartDate,
            element.EndDate,
            element.SessionTrainingMasterId,
            element.feedBackDetails,
            element.SessionTrainingName,
            element.SessionTrainingType,
            element.YearsOfExpWithRPG,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ).then(
          (data) => { },
          (error) => {
            // alert('Report Error inserting location content : ' + error);
            this.sharedPrefrence.get('language').then((lang) => {
              this.sharedPrefrence.get(lang).then((l) => {
                alert(
                  l.databaseHelper_service.report_error +
                  'inserting Session and Training content : ' +
                  error
                );
              });
            });
            // alert('ERROR: ' + JSON.stringify(error.err));
          }
        );
      });
    }
  }

  insertFeedback(feedbackList: Array<Feedback>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let feedbackInsertList = [];
      feedbackList.forEach((element) => {
        feedbackInsertList.push([
          'INSERT INTO feedback (FeedBackId ,SessionTrainingMasterId ,StartDate ,EndDate ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ,SessionTrainingType,SessionTrainingName ) VALUES (?,?,?,?,?,?,?,?,?,?)',
          [
            element.FeedBackId,
            element.SessionTrainingMasterId,
            element.StartDate,
            element.EndDate,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn,
            element.SessionTrainingType,
            element.SessionTrainingName
          ]
        ]);
      });
      this.DB.sqlBatch(feedbackInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertLocation(locationList: Array<Location>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      locationList.forEach((element) => {
        this.DB.executeSql(
          'INSERT INTO location (LocationId INTEGER,LocationName TEXT,CreatedBy TEXT,CreatedOn TEXT,UpdatedBy TEXT,UpdatedOn TEXT) VALUES (?,?,?,?,?,?)',
          [
            element.LocationId,
            element.LocationName,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ).then(
          (data) => { },
          (error) => {
            // alert('Report Error inserting location content : ' + error);
            this.sharedPrefrence.get('language').then((lang) => {
              this.sharedPrefrence.get(lang).then((l) => {
                alert(
                  l.databaseHelper_service.report_error +
                  'inserting location content : ' +
                  error
                );
              });
            });
            // alert('ERROR: ' + JSON.stringify(error.err));
          }
        );
      });
    }
  }

  insertSchoolData(schoolDataList: Array<SchoolData>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      schoolDataList.forEach(
        (element) => {
          this.DB.executeSql(
            'INSERT INTO schooldata (SchoolId ,UdiseNo ,SchoolName ,ShortName ,AddressLine1 ,AddressLine2 ,Pincode ,' +
            'LocationId ,GradesTaught ,Medium ,NoOfStudents ,NoOfTeachers ,HeadMaster ,SchoolTime ,CreatedBy ,CreatedOn ,' +
            'UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
            [
              element.SchoolId,
              element.UdiseNo,
              element.SchoolName,
              element.ShortName,
              element.AddressLine1,
              element.AddressLine2,
              element.Pincode,
              element.LocationId,
              element.GradesTaught,
              element.Medium,
              element.NoOfStudents,
              element.NoOfTeachers,
              element.HeadMaster,
              element.SchoolTime,
              element.CreatedBy,
              element.CreatedOn,
              element.UpdatedBy,
              element.UpdatedOn
            ]
          ).then((data) => { });
        },
        (error) => {
          // alert('Report Error inserting schooldata content : ' + error);
          this.sharedPrefrence.get('language').then((lang) => {
            this.sharedPrefrence.get(lang).then((l) => {
              alert(
                l.databaseHelper_service.report_error +
                'inserting schooldata content : ' +
                error
              );
            });
          });
          // alert('ERROR: ' + JSON.stringify(error.err));
        }
      );
    }
  }

  insertTeachersData(teachersDataList: Array<TeachersData>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      teachersDataList.forEach(
        (element) => {
          this.DB.executeSql(
            'INSERT INTO teachersdata (RpgId ,Dob ,Email ,Gender ,UdiseNo ,ClassesTaught ,SubjectsTaught ,HighestDegree ,' +
            'YearsOfExp ,YearsOfExpWithRPG,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
            [
              element.RpgId,
              element.Dob,
              element.Email,
              element.Gender,
              element.UdiseNo,
              element.ClassesTaught,
              element.SubjectsTaught,
              element.HighestDegree,
              element.YearsOfExp,
              element.YearsOfExpWithRPG,
              element.CreatedBy,
              element.CreatedOn,
              element.UpdatedBy,
              element.UpdatedOn
            ]
          ).then((data) => { });
        },
        (error) => {
          // alert('Report Error inserting teachersdata content : ' + error);
          this.sharedPrefrence.get('language').then((lang) => {
            this.sharedPrefrence.get(lang).then((l) => {
              alert(
                l.databaseHelper_service.report_error +
                'inserting teachersdata content : ' +
                error
              );
            });
          });
          // alert('ERROR: ' + JSON.stringify(error.err));
        }
      );
    }
  }

  insertArticles(articlesList: Array<Article>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      let articlesInsertList = [];
      articlesList.forEach((element) => {
        articlesInsertList.push([
          'INSERT INTO articles (ArticleId ,ResourceId ,Image ,Description ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?)',
          [
            element.ArticleId,
            element.ResourceId,
            element.Image,
            element.Description,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(articlesInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertContestDetails(contestDetailsList: Array<ContestDetail>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      contestDetailsList.forEach(
        (element) => {
          this.DB.executeSql(
            'INSERT INTO contestdetails (ContestId ,ContestText ,EvaluationCriterias ,ContestStartDate ,ContestEndDate,EvaluationStatus ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?)',
            [
              element.ContestId,
              element.ContestText,
              element.EvaluationCriterias,
              element.ContestStartDate,
              element.ContestEndDate,
              element.EvaluationStatus,
              element.CreatedBy,
              element.CreatedOn,
              element.UpdatedBy,
              element.UpdatedOn
            ]
          ).then((data) => { });
        },
        (error) => {
          // alert('Report Error inserting contestdetails content : ' + error);
          this.sharedPrefrence.get('language').then((lang) => {
            this.sharedPrefrence.get(lang).then((l) => {
              alert(
                l.databaseHelper_service.report_error +
                'inserting contestdetails content : ' +
                error
              );
            });
          });
          // alert('ERROR: ' + JSON.stringify(error.err));
        }
      );
    }
  }

  insertQuestion(questionList: Array<Question>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let questionInsertList = [];
      questionList.forEach((element) => {
        questionInsertList.push([
          'INSERT INTO question (QuestionId ,QuizId ,SessionTrainingMasterId,QuestionType ,QuestionText ,Options ,Answer ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?,?)',
          [
            element.QuestionId,
            element.QuizId,
            element.SessionTrainingMasterId,
            element.QuestionType,
            element.QuestionText,
            element.Options,
            element.Answer,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(questionInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertQuizDetails(quizDetailsList: Array<QuizeDetail>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let quizDetailsInsertList = [];
      quizDetailsList.forEach((element) => {
        quizDetailsInsertList.push([
          'INSERT INTO quizdetails (QuizId ,QuizName ,isActive ,CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?)',
          [
            element.QuizId,
            element.QuizName,
            element.isActive,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(quizDetailsInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertResource(resourceList: Array<Resource>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let resourceInsertList = [];
      resourceList.forEach((element) => {
        resourceInsertList.push([
          'INSERT INTO resource (ResourceId ,ResourceType ,Header ,ResourceLink ,ResourceDescription, TargetAudienceType, Category,SubCategory, CreatedBy ,CreatedOn ,UpdatedBy ,UpdatedOn ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?)',
          [
            element.ResourceId,
            element.ResourceType,
            element.Header,
            element.ResourceLink,
            element.ResourceDescription,
            element.TargetAudienceType,
            element.Category,
            element.SubCategory,
            element.CreatedBy,
            element.CreatedOn,
            element.UpdatedBy,
            element.UpdatedOn
          ]
        ]);
      });
      this.DB.sqlBatch(resourceInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  insertAllUserResponses(UserResponseList: Array<UserResponse>) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      let UserResponseInsertList = [];
      UserResponseList.forEach((userResponses) => {
        UserResponseInsertList.push([
          'INSERT INTO  userresponses (Id ,RpgId ,Type ,DataLink ,ContestGrade ,QuizId ,ContestId,OthersId ,AnswerJson ,QuizPoints ,ContestPoints ,OthersPoints,' +
          'TotalPoints ,QuizTakenDate ,ContestTakenDate ,ViewedDate,CreatedBy ,CreatedOn ,IsSynced,SessionTrainingDate,FeedBackDate ) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
          [
            userResponses.Id,
            userResponses.RpgId,
            userResponses.Type,
            userResponses.DataLink,
            userResponses.ContestGrade,
            userResponses.QuizId,
            userResponses.ContestId,
            userResponses.OthersId,
            userResponses.AnswerJson,
            userResponses.QuizPoints,
            userResponses.ContestPoints,
            userResponses.OthersPoints,
            userResponses.TotalPoints,
            userResponses.QuizTakenDate,
            userResponses.ContestTakenDate,
            userResponses.ViewedDate,
            userResponses.CreatedBy,
            userResponses.CreatedOn,
            userResponses.IsSynced,
            userResponses.SessionTrainingDate,
            userResponses.FeedBackDate
          ]
        ]);
      });
      this.DB.sqlBatch(UserResponseInsertList).then(
        () => {
          resolve(true);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }
  insertLMSData(RpgId,standard,level) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      this.DB.executeSql(
        'INSERT INTO  LMSOnboard (RpgId, standard,level ) values(?,?,?)',
        [
          RpgId,standard,level
        ]
      )
        .then((data) => { })
        .catch(function (error) { });
    }

  }
  insertUserResponses(userResponses: UserResponse) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    if (this.DB != null) {
      this.DB.executeSql(
        'INSERT INTO  userresponses (Id ,RpgId ,Type ,DataLink ,ContestGrade ,QuizId ,ContestId ,OthersId,AnswerJson ,QuizPoints ,ContestPoints ,OthersPoints,' +
        'TotalPoints ,QuizTakenDate ,ContestTakenDate ,ViewedDate,CreatedBy ,CreatedOn ,IsSynced,SessionTrainingDate, FeedBackDate) VALUES (?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?,?)',
        [
          userResponses.Id,
          userResponses.RpgId,
          userResponses.Type,
          userResponses.DataLink,
          userResponses.ContestGrade,
          userResponses.QuizId,
          userResponses.ContestId,
          userResponses.OthersId,
          userResponses.AnswerJson,
          userResponses.QuizPoints,
          userResponses.ContestPoints,
          userResponses.OthersPoints,
          userResponses.TotalPoints,
          userResponses.QuizTakenDate,
          userResponses.ContestTakenDate,
          userResponses.ViewedDate,
          userResponses.CreatedBy,
          userResponses.CreatedOn,
          userResponses.IsSynced,
          userResponses.SessionTrainingDate,
          userResponses.FeedBackDate
        ]
      )
        .then((data) => { })
        .catch(function (error) { });
    }
  }

  getQuizeDetails() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let quizDetailsList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select QuizId ,QuizName from quizdetails where isActive = ?',
              [1]
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  quizDetailsList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(quizDetailsList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }
 

  getQuizData(quizId) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let quizDataList = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from question where QuizId = ?', [
              quizId
            ]).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  quizDataList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(quizDataList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getSyncData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {


      let quizDataList = [];
      if (this.DB != null) {
        this.DB.executeSql(
          'select * from userresponses where IsSynced = 0',
          []
        ).then(
          (data) => {
            for (let i = 0; i < data.rows.length; i++) {
              quizDataList[i] = data.rows.item(i);
            }

            resolve(quizDataList);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  getLmsData(RpgId) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {


      let LmsList = [];
      if (this.DB != null) {
        this.DB.executeSql(
          "SELECT standard,level FROM LMSOnboard WHERE RpgId=?",[RpgId]
        ).then(
          (data) => {
            for (let i = 0; i < data.rows.length; i++) {
              LmsList[i] = data.rows.item(i);
            }

            resolve(LmsList);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  getAttendanceData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {


      let attendanceDataList = [];
      if (this.DB != null) {
        this.DB.executeSql(
          'select * from saveAttendanceDetailsTeacherAttendance',
          []
        ).then(
          (data) => {
            for (let i = 0; i < data.rows.length; i++) {
              attendanceDataList[i] = data.rows.item(i);
            }

            resolve(attendanceDataList);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  }

  getParticipatedQuiz() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let quizDataList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select QuizId from userresponses where Type = ?',
              ['Quiz']
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  quizDataList[i] = data.rows.item(i).QuizId;
                }

                loadEl.dismiss();
                resolve(quizDataList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getParticipatedQuizData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let quizDataList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select QuizId, QuizPoints from userresponses where Type = ?',
              ['Quiz']
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  quizDataList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(quizDataList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  updateSyncData(syncdata: UserResponse) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    this.DB.executeSql(
      'Update userresponses set IsSynced = ? where QuizId = ? and RpgId = ?',
      [1, syncdata.QuizId, syncdata.RpgId]
    ).then((data) => { });
  }

  updateOtherSyncData(syncdata: UserResponse) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    this.DB.executeSql(
      'Update userresponses set IsSynced = ? where ViewedDate = ? and RpgId = ? and Id = ? and Type = ?',
      [1, syncdata.ViewedDate, syncdata.RpgId, syncdata.Id, syncdata.Type]
    ).then((data) => { });
  }

  checkOtherIsClickedToday(type) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          if (this.DB != null) {
            this.DB.executeSql(
              'select * from userresponses where ViewedDate = ? and Type = ?',
              [this.datepipe.transform(new Date(), 'yyyy-MM-dd'), type]
            ).then(
              (data) => {
                loadEl.dismiss();
                resolve(data.rows.length);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  checkOtherIsClickedEver(type, id) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          if (this.DB != null) {
            this.DB.executeSql(
              'select * from userresponses where OthersId = ? and Type = ?',
              [id, type]
            ).then(
              (data) => {
                loadEl.dismiss();
                resolve(data.rows.length);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  deleteArticles() {
    this.DB.executeSql('delete from articles').then(
      (d) => { },
      (e) => { }
    );
  }

  deleteResource() {
    this.DB.executeSql('delete from resource').then(
      (d) => { },
      (e) => { }
    );
  }

  deleteSaveAttendanceDetails() {
    this.DB.executeSql(
      'delete from saveAttendanceDetailsTeacherAttendance'
    ).then(
      (d) => { },
      (e) => { }
    );
  }

  deleteQuizeDetails() {
    this.DB.executeSql('delete from quizdetails').then(
      (d) => { },
      (e) => { }
    );
  }

  deleteQuestion() {
    this.DB.executeSql('delete from question').then(
      (d) => { },
      (e) => { }
    );
  }
  deleteFeedback() {
    this.DB.executeSql('delete from feedback').then(
      (d) => { },
      (e) => { }
    );
  }

  deleteUserResponses() {
    this.DB.executeSql('delete from userresponses').then(
      (d) => { },
      (e) => { }
    );
  }

  deleteCategoryDetails() {
    this.DB.executeSql('delete from categorydetails').then(
      (d) => { },
      (e) => { }
    );
  }
  deleteTeachersDataForFacilitator() {
    this.DB.executeSql('delete from teachersData').then(
      (d) => { },
      (e) => { }
    );
  }

  dropAllTables() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.DB.executeSql('drop table if exists articles', [])
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists resource', []);
          },
          (e) => {
            reject('Error dropping articles table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists quizdetails', []);
          },
          (e) => {
            reject('Error dropping resource table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists question', []);
          },
          (e) => {
            reject('Error dropping quizdetails table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists feedback', []);
          },
          (e) => {
            reject('Error dropping question table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql(
              'drop table if exists categorydetails',
              []
            );
          },
          (e) => {
            reject('Error dropping feedback table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql(
              'drop table if exists contestdetails',
              []
            );
          },
          (e) => {
            reject('Error dropping categorydetails table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists userresponses', []);
          },
          (e) => {
            reject('Error dropping contestdetails table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql(
              'drop table if exists FacilitatorCohortsMapping',
              []
            );
          },
          (e) => {
            reject('Error dropping userresponses table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql(
              'drop table if exists ScheduleDetails',
              []
            );
          },
          (e) => {
            reject('Error dropping FacilitatorCohortsMapping table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql('drop table if exists teachersData', []);
          },
          (e) => {
            reject('Error dropping ScheduleDetails table.');
          }
        )
        .then(
          (d) => {
            return this.DB.executeSql(
              'drop table if exists saveAttendanceDetailsTeacherAttendance',
              []
            );
          },
          (e) => {
            reject('Error dropping teachersData table.');
          }
        )
        .then(
          (d) => {
            resolve(true);
          },
          (e) => {
            reject(
              'Error dropping saveAttendanceDetailsTeacherAttendance table.'
            );
          }
        );
    });
  }

  deleteRecord(isLogin) {

    if (this.DB == null) {
      this.createOrOpenDB();
    }
    if (this.DB != null) {
      this.DB.executeSql('delete from userresponses').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from resource').then(
        (d) => { },
        (e) => { }
      );

      this.DB.executeSql('delete from ByLine').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from quizdetails').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from question').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from contestdetails').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from articles').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from feedback').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from categorydetails').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from FacilitatorCohortsMapping').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from ScheduleDetails').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql('delete from teachersData').then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql(
        'delete from saveAttendanceDetailsTeacherAttendance'
      ).then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql(
        'delete from CoordinatorCohortsMapping'
      ).then(
        (d) => { },
        (e) => { }
      );
      this.DB.executeSql(
        'delete from RoleTypeMapping'
      ).then(
        (d) => { },
        (e) => { }
      );
      if (isLogin === 1) {
        this.sharedPrefrence.remove('loginData').then(
          (d) => { },
          (e) => { }
        );
        this.sharedPrefrence.remove('photo').then(
          (d) => { },
          (e) => { }
        );
      }
    }
  }

  getResources(resType, targetAudType) {
    // if (this.DB == null) {
    //   this.createOrOpenDB();
    // }
    return new Promise((resolve, reject) => {
      // this.loadingController
      //   .create({
      //     message: 'Please wait...'
      //   })
      //   .then((loadEl) => {
      //     loadEl.present();


      //     var query = 'select * from resource where ResourceType = ? and TargetAudienceType = ?';
      //     // if(targetAudType == null)
      //     // {
      //     //   query =  'select * from resource where ResourceType = ?'; 
      //     // }

      //     let videoList = [];
      //     if (this.DB != null) {
      //       this.DB.executeSql(
      //         query,
      //         [resType, targetAudType]
      //       ).then(
      //         (data) => {
      //           for (let i = 0; i < data.rows.length; i++) {
      //             videoList[i] = data.rows.item(i);
      //           }

      //           loadEl.dismiss();
      //           resolve(videoList);
      //         },
      //         (error) => {
      //           loadEl.dismiss();
      //           reject(error);
      //         }
      //       );
      //     }
      //   });
    });
  }



  getResourcesCatagaory(Category, resType, targetAudType) {
    // if (this.DB == null) {
    //   this.createOrOpenDB();
    // }
    return new Promise((resolve, reject) => {

      this.api.getResourceByType(resType,targetAudType,Category).then(res=>{
        resolve(res);
      })
      // this.loadingController
      //   .create({
      //     message: 'Please wait...'
      //   })
      //   .then((loadEl) => {
      //     loadEl.present();


      //     var query = 'select * from resource where Category = ? and ResourceType = ? and TargetAudienceType = ?';

      //     let videoList = [];
      //     if (this.DB != null) {
      //       this.DB.executeSql(
      //         query,
      //         [Category, resType, targetAudType]
      //       ).then(
      //         (data) => {
                
      //           for (let i = 0; i < data.rows.length; i++) {
      //             videoList[i] = data.rows.item(i);
      //           }

      //           loadEl.dismiss();
      //           resolve(videoList);
      //         },
      //         (error) => {
      //           loadEl.dismiss();
      //           reject(error);
      //         }
      //       );
      //     }
      //   });
    });
  }

  getResourcesCatagaory1(targetAudType) {
    // if (this.DB == null) {
    //   this.createOrOpenDB();
    // }
    return new Promise((resolve, reject) => {
      // this.loadingController
      //   .create({
      //     message: 'Please wait...'
      //   })
      //   .then((loadEl) => {
      //     loadEl.present();


      //     var query = 'select * from resource where  TargetAudienceType = ?';

      //     let videoList = [];
      //     if (this.DB != null) {
      //       this.DB.executeSql(
      //         query,
      //         [targetAudType]
      //       ).then(
      //         (data) => {
      //           for (let i = 0; i < data.rows.length; i++) {
      //             videoList[i] = data.rows.item(i);
      //           }

      //           loadEl.dismiss();
      //           resolve(videoList);
      //         },
      //         (error) => {
      //           loadEl.dismiss();
      //           reject(error);
      //         }
      //       );
      //     }
      //   });

      this.api.getResourceByType("",targetAudType,"").then(res=>{
        resolve(res);
      })
    });
  }

  getResourcesRadioChannels(resType, targetAudType) {
    // if (this.DB == null) {
    //   this.createOrOpenDB();
    // }
    return new Promise((resolve, reject) => {
      this.api.getResourceByType(resType,targetAudType,"").then(res=>{
        resolve(res);
      })

      // this.loadingController
      //   .create({
      //     message: 'Please wait...'
      //   })
      //   .then((loadEl) => {
      //     loadEl.present();


      //     var query = 'select * from resource where ResourceType = ? and TargetAudienceType = ?';

      //     let videoList = [];
      //     if (this.DB != null) {
      //       this.DB.executeSql(
      //         query,
      //         [resType, targetAudType]
      //       ).then(
      //         (data) => {
      //           for (let i = 0; i < data.rows.length; i++) {
      //             videoList[i] = data.rows.item(i);
      //             videoList[i].Category = videoList[i].Header;
      //           }

      //           loadEl.dismiss();
      //           resolve(videoList);
      //         },
      //         (error) => {
      //           loadEl.dismiss();
      //           reject(error);
      //         }
      //       );
      //     }
      //   });
    });
  }

  getCategories(audienceType, resourceType) {
    // if (this.DB == null) {
    //   this.createOrOpenDB();
    // }
    // return new Promise((resolve, reject) => {
    //   this.loadingController
    //     .create({
    //       message: 'Please wait...'
    //     })
    //     .then((loadEl) => {
    //       loadEl.present();

    //       let categoryList = [];
    //       if (this.DB != null) {
    //         this.DB.executeSql(
    //           'select * from categorydetails where ResourceType = ? and TargetAudienceType = ?',
    //           [resourceType, audienceType]
    //         ).then(
    //           (data) => {
    //             for (let i = 0; i < data.rows.length; i++) {
    //               categoryList[i] = data.rows.item(i);
    //             }

    //             loadEl.dismiss();
    //             resolve(categoryList);
    //           },
    //           (error) => {
    //             loadEl.dismiss();
    //             reject(error);
    //           }
    //         );
    //       }
    //     });
    // });

    return new Promise((resolve, reject) => {
      this.api.onlineGetCategoryDetails(audienceType,resourceType).then((res:any)=>{
        resolve(res.Results);
      })
    })
  }

  getActivityDetails(ResourceId) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.api.activityDetailsByResourceId(ResourceId).then((res:any)=>{
        
        resolve(res);
      },err=>{
        reject(err);
      })
      // this.loadingController
      //   .create({
      //     message: 'Please wait...'
      //   })
      //   .then((loadEl) => {
      //     loadEl.present();

      //     let categoryList = [];
      //     if (this.DB != null) {
      //       this.DB.executeSql(
      //         'select * from ActivityDetails where ResourceId = ? ',
      //         [ResourceId]
      //       ).then(
      //         (data) => {
      //           for (let i = 0; i < data.rows.length; i++) {
      //             categoryList[i] = data.rows.item(i);
      //           }

      //           loadEl.dismiss();
      //           resolve(categoryList);
      //         },
      //         (error) => {
      //           loadEl.dismiss();
      //           reject(error);
      //         }
      //       );
      //     }
      //   });
    });
  }

  getArticles() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let articleList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select * from resource where ResourceType = "Article"',
              []
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  articleList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(articleList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getSingleArticle(resId) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let singleArticleList = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from articles where ResourceId = ?', [
              resId
            ]).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  singleArticleList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(singleArticleList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getByLine(audienceType) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let singleArticleList = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from ByLine where TargetAudienceType = ?', [
              audienceType
            ]).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  singleArticleList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(singleArticleList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }


  getFeedbackData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let feedback = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from feedback', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  feedback[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(feedback);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getFeedbackQuestion(id) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let feedbackQtList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select * from question where SessionTrainingMasterId = ?',
              [id]
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  feedbackQtList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(feedbackQtList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getParticipatedFeedbackData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let feedbackDataList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select OthersId from userresponses where Type = ?',
              ['Feedback']
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  feedbackDataList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(feedbackDataList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getContestData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let contests = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from contestdetails', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  contests[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(contests);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getContestQuestion(id) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let contestQtList = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from question where ContestId = ?', [
              id
            ]).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  contestQtList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(contestQtList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getResources1(audienceType, resourceType) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();

          let categoryList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select * from resource where ResourceType = ? and TargetAudienceType = ?  ORDER BY ResourceId DESC',
              [resourceType, audienceType]
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  categoryList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(categoryList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getParticipatedContestData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let contestDataList = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select ContestId from userresponses where Type = ?',
              ['Contest']
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  contestDataList[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(contestDataList);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getScheduleDetailsData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let scheduleDets = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from ScheduleDetails', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  scheduleDets[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(scheduleDets);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getFeedbackId(sessId) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let scheduleDets = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select * from feedback where SessionTrainingMasterId = ?',
              [sessId]
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  scheduleDets[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(scheduleDets);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getTeachers(cohort) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let teachers = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from teachersData where Cohort = ?', [
              cohort
            ]).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  teachers[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(teachers);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getSaveAttendanceDetails() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let teachers = [];
          if (this.DB != null) {
            this.DB.executeSql(
              'select * from saveAttendanceDetailsTeacherAttendance',
              []
            ).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  data.rows.item(i).teacherAttendanceJson = JSON.parse(
                    data.rows.item(i).teacherAttendanceJson
                  );
                  teachers[i] = data.rows.item(i);
                }

                let saveAttModel = new SaveAttendanceDetails();
                this.sharedPrefrence.get('loginData').then((r) => {
                  saveAttModel.FacilitatorId = r.RpgId;
                  saveAttModel.CreatedBy = r.RpgId.toString();
                  saveAttModel.attendanceJson = teachers;
                  saveAttModel.CreatedOn = new Date().toString();
                  loadEl.dismiss();
                  resolve(saveAttModel);
                });
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getLeaderboardDetails() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let leaderboardDetails = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from leaderboard', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  leaderboardDetails[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(leaderboardDetails);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getLeaderboardRoleDetails() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let leaderboardDetails = [];
          if (this.DB != null) {
            this.DB.executeSql('select distinct Role from leaderboard', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  leaderboardDetails[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(leaderboardDetails);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getMyPointsDetails() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();


          let MyPointsDetails = [];
          if (this.DB != null) {
            this.DB.executeSql('select * from MyPoints', []).then(
              (data) => {
                for (let i = 0; i < data.rows.length; i++) {
                  MyPointsDetails[i] = data.rows.item(i);
                }

                loadEl.dismiss();
                resolve(MyPointsDetails);
              },
              (error) => {
                loadEl.dismiss();
                reject(error);
              }
            );
          }
        });
    });
  }

  getRoleTypeMappingData() {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      // let RTMDetails = [];
      // if (this.DB != null) {
      //   this.DB.executeSql('select * from RoleTypeMapping', []).then(
      //     (data) => {
      //       for (let i = 0; i < data.rows.length; i++) {
      //         RTMDetails[i] = data.rows.item(i);
      //       }

      //       resolve(RTMDetails);
      //     },
      //     (error) => {
      //       reject(error);
      //     }
      //   );
      // }
      resolve([]);
    });
  }

  dropTable(tableName) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }
    return new Promise((resolve, reject) => {
      this.DB.executeSql('drop table if exists ' + tableName, []).then(
        (d) => {
          resolve(true);
        },
        (e) => {
          reject('Error dropping ' + tableName + ' table.');
        }
      );
    });
  }

  createTableByName(tableName) {
    if (this.DB == null) {
      this.createOrOpenDB();
    }

    return new Promise((resolve, reject) => {
      this.DB.executeSql(this.createTableQueryObject[tableName], []).then(
        () => {
          resolve(true);
        },
        (err) => {
          console.log(err);
          reject('Error creating ' + tableName + ' table.');
        }
      );
    });
  }

  getDbData() {
    this.DB.executeSql('select * from ActivityDetails', []).then((data) => {
      console.log('ActivityDetails: ', data);
    });
    this.DB.executeSql('select * from categorydetails', []).then((data) => {
      console.log('categorydetails: ', data);
    });
    this.DB.executeSql('select * from resource', []).then((data) => {
      console.log('resource: ', data);
    });
    this.DB.executeSql('select * from question', []).then((data) => {
      console.log('question: ', data);
    });
    this.DB.executeSql('select * from feedback', []).then((data) => {
      console.log('feedback: ', data);
    });
    this.DB.executeSql('select * from userresponses', []).then((data) => {
      console.log('userresponses: ', data);
    });
  }
}
