import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-animation-modal',
  templateUrl: './animation-modal.page.html',
  styleUrls: ['./animation-modal.page.scss'],
})
export class AnimationModalPage implements OnInit {
  animationUrl;
  constructor(private navParams: NavParams) { }

  ngOnInit() {
    this.animationUrl = this.navParams.data.animationUrl;
    console.log(this.navParams);

  }

  ionViewWillEnter(){
    this.animationUrl = this.navParams.data.animationUrl;

  }

}
