import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Congratulations2Component } from './congratulations2/congratulations2.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule)
  },
  { path: 'main', loadChildren: './main/main.module#MainPageModule' },
  {
    path: 'settings',
    loadChildren: './settings/settings.module#SettingsPageModule'
  },
  { path: 'quiz', loadChildren: './quiz/quiz.module#QuizPageModule' },
  {
    path: 'resources',
    loadChildren: './resources/resources.module#ResourcesPageModule'
  },
  {
    path: 'single-article',
    loadChildren:
      './resources/articles/single-article/single-article.module#SingleArticlePageModule'
  },
  {
    path: 'quizlist',
    loadChildren: './quizlist/quizlist.module#QuizlistPageModule'
  },
  { path: 'congratulations2', component: Congratulations2Component },
  { path: 'congratulations', loadChildren: './congratulations/congratulations.module#CongratulationsPageModule' },
  { path: 'quizresult', loadChildren: './quizresult/quizresult.module#QuizresultPageModule' },
  { path: 'contests', loadChildren: './contests/contests.module#ContestsPageModule' },
  { path: 'syncview', loadChildren: './syncview/syncview.module#SyncviewPageModule' },
  { path: 'totm', loadChildren: './totm/totm.module#TotmPageModule' },
  { path: 'win', loadChildren: './win/win.module#WinPageModule' },
  { path: 'feedback', loadChildren: './feedback/feedback.module#FeedbackPageModule' },
  { path: 'feedbackquestions', loadChildren: './feedback/feedbackquestions/feedbackquestions.module#FeedbackquestionsPageModule' },
  { path: 'feedbackcongratulations', loadChildren: './feedback/feedbackcongratulations/feedbackcongratulations.module#FeedbackcongratulationsPageModule' },
  { path: 'feedbackdetails', loadChildren: './feedback/feedbackdetails/feedbackdetails.module#FeedbackdetailsPageModule' },
  { path: 'contestslist', loadChildren: './contests/contestslist/contestslist.module#ContestslistPageModule' },
  { path: 'contestscongratulations', loadChildren: './contests/contestscongratulations/contestscongratulations.module#ContestscongratulationsPageModule' },
  { path: 'buildyourself', loadChildren: './buildyourself/buildyourself.module#BuildyourselfPageModule' },
  { path: 'intheclassroom', loadChildren: './intheclassroom/intheclassroom.module#IntheclassroomPageModule' },
  { path: 'byletspromise', loadChildren: './buildyourself/byletspromise/byletspromise.module#ByletspromisePageModule' },
  { path: 'byletsread', loadChildren: './buildyourself/byletsread/byletsread.module#ByletsreadPageModule' },
  { path: 'byletswin', loadChildren: './buildyourself/byletswin/byletswin.module#ByletswinPageModule' },
  { path: 'byletswatch', loadChildren: './buildyourself/byletswatch/byletswatch.module#ByletswatchPageModule' },
  { path: 'byletslisten', loadChildren: './buildyourself/byletslisten/byletslisten.module#ByletslistenPageModule' },
  { path: 'itcletspromise', loadChildren: './intheclassroom/itcletspromise/itcletspromise.module#ItcletspromisePageModule' },
  { path: 'itcletsdoit', loadChildren: './intheclassroom/itcletsdoit/itcletsdoit.module#ItcletsdoitPageModule' },
  { path: 'itcletsdoitthepaw', loadChildren: './intheclassroom/itcletsdoitthepaw/itcletsdoitthepaw.module#ItcletsdoitthepawPageModule' },
  { path: 'itcletsread', loadChildren: './intheclassroom/itcletsread/itcletsread.module#ItcletsreadPageModule' },
  { path: 'itcletswatch', loadChildren: './intheclassroom/itcletswatch/itcletswatch.module#ItcletswatchPageModule' },

  {
    path: 'articles',
    loadChildren: './resources/articles/articles.module#ArticlesPageModule'
  },
  { path: 'booklist', loadChildren: './buildyourself/byletsread/booklist/booklist.module#BooklistPageModule' },
  { path: 'videos', loadChildren: './resources/videos/videos.module#VideosPageModule' },
  { path: 'singlepromise', loadChildren: './buildyourself/byletspromise/singlepromise/singlepromise.module#SinglepromisePageModule' },
  { path: 'singlebooklist', loadChildren: './buildyourself/byletsread/booklist/singlebooklist/singlebooklist.module#SinglebooklistPageModule' },
  { path: 'singlepromiseitc', loadChildren: './intheclassroom/itcletspromise/singlepromiseitc/singlepromiseitc.module#SinglepromiseitcPageModule' },
  { path: 'singleit', loadChildren: './intheclassroom/itcletsdoit/singleit/singleit.module#SingleitPageModule' },
  { path: 'booklistitc', loadChildren: './intheclassroom/itcletsread/booklistitc/booklistitc.module#BooklistitcPageModule' },
  { path: 'singlebooklistitc', loadChildren: './intheclassroom/itcletsread/booklistitc/singlebooklistitc/singlebooklistitc.module#SinglebooklistitcPageModule' },
  { path: 'singleitcletsdoitthepaw', loadChildren: './intheclassroom/itcletsdoitthepaw/singleitcletsdoitthepaw/singleitcletsdoitthepaw.module#SingleitcletsdoitthepawPageModule' },
  { path: 'itcletslisten', loadChildren: './intheclassroom/itcletslisten/itcletslisten.module#ItcletslistenPageModule' },
  { path: 'change-password', loadChildren: './change-password/change-password.module#ChangePasswordPageModule' },
  { path: 'edit-profile', loadChildren: './edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'schedule-details', loadChildren: './schedule-details/schedule-details.module#ScheduleDetailsPageModule' },
  { path: 'attendance', loadChildren: './feedback/attendance/attendance.module#AttendancePageModule' },
  { path: 'slider', loadChildren: './slider/slider.module#SliderPageModule' },
  { path: 'activities', loadChildren: './intheclassroom/activities/activities.module#ActivitiesPageModule' },
  { path: 'energizers', loadChildren: './intheclassroom/energizers/energizers.module#EnergizersPageModule' },
  { path: 'about-us', loadChildren: './about-us/about-us.module#AboutUsPageModule' },
  { path: 'faq', loadChildren: './faq/faq.module#FaqPageModule' },
  { path: 'itc-letsdoitpaw-single-select', loadChildren: './intheclassroom/itcletsdoitthepaw/singleitcletsdoitthepaw/itc-letsdoitpaw-single-select/itc-letsdoitpaw-single-select.module#ItcLetsdoitpawSingleSelectPageModule' },
  { path: 'itc-letsdoit-single-select', loadChildren: './intheclassroom/itcletsdoit/singleit/itc-letsdoit-single-select/itc-letsdoit-single-select.module#ItcLetsdoitSingleSelectPageModule' },
  { path: 'single-energizer', loadChildren: './intheclassroom/energizers/single-energizer/single-energizer.module#SingleEnergizerPageModule' },
  { path: 'single-activity', loadChildren: './intheclassroom/activities/single-activity/single-activity.module#SingleActivityPageModule' },
  { path: 'attendance-absent-reason', loadChildren: './feedback/attendance-absent-reason/attendance-absent-reason.module#AttendanceAbsentReasonPageModule' },
  { path: 'itc-story-a-day', loadChildren: './intheclassroom/itc-story-a-day/itc-story-a-day.module#ItcStoryADayPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'welcome', loadChildren: './welcome/welcome.module#WelcomePageModule' },
  { path: 'dd-videos', loadChildren: './dd-videos/dd-videos.module#DdVideosPageModule' },
  { path: 'add-program', loadChildren: './add-program/add-program.module#AddProgramPageModule' },
  { path: 'teachersdesk', loadChildren: './teachersdesk/teachersdesk.module#TeachersdeskPageModule' },
  { path: 'studenthome', loadChildren: './studenthome/studenthome.module#StudenthomePageModule' },
  { path: 'studentbeginer', loadChildren: './studentbeginer/studentbeginer.module#StudentbeginerPageModule' },
  { path: 'studentintermediate', loadChildren: './studentintermediate/studentintermediate.module#StudentintermediatePageModule' },
  { path: 'studentadvance', loadChildren: './studentadvance/studentadvance.module#StudentadvancePageModule' },
  { path: 'viewworksheet', loadChildren: './studentbeginer/viewworksheet/viewworksheet.module#ViewworksheetPageModule' },  
   { path: 'intermediateworksheet', loadChildren: './intermediateworksheet/intermediateworksheet.module#IntermediateworksheetPageModule' },   
   { path: 'advancedworksheet', loadChildren: './advancedworksheet/advancedworksheet.module#AdvancedworksheetPageModule' },   { path: 'listofcohorts', loadChildren: './listofcohorts/listofcohorts.module#ListofcohortsPageModule' },
  { path: 'add-facilitator-cohort', loadChildren: './add-facilitator-cohort/add-facilitator-cohort.module#AddFacilitatorCohortPageModule' },
  { path: 'postdetails', loadChildren: './postdetails/postdetails.module#PostdetailsPageModule' },
  { path: 'postcomment', loadChildren: './postcomment/postcomment.module#PostcommentPageModule' },
  { path: 'unapproved-post', loadChildren: './unapproved-post/unapproved-post.module#UnapprovedPostPageModule' },
  { path: 'course-section-page', loadChildren: './lms/course-section-page/course-section-page.module#CourseSectionPagePageModule' },
  { path: 'section-details', loadChildren: './lms/section-details/section-details.module#SectionDetailsPageModule' },
  { path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'levels', loadChildren: './levels/levels.module#LevelsPageModule' },
  { path: 'course-details', loadChildren: './course-details/course-details.module#CourseDetailsPageModule' },
  { path: 'animation-modal', loadChildren: './lms/section-details/animation-modal/animation-modal/animation-modal.module#AnimationModalPageModule' },
  { path: 'all-courses', loadChildren: './lms/all-courses/all-courses.module#AllCoursesPageModule' },
  { path: 'teacher-level', loadChildren: './lms/teacher-level/teacher-level.module#TeacherLevelPageModule' },
  { path: 'role-section', loadChildren: './role-section/role-section.module#RoleSectionPageModule' },
  { path: 'standard-list', loadChildren: './intheclassroom/itcletsdoit/standard-list/standard-list.module#StandardListPageModule' },
  { path: 'student-assessment', loadChildren: './student-assessment/student-assessment.module#StudentAssessmentPageModule' },
  { path: 'assessment-standard', loadChildren: './student-assessment/assessment-standard/assessment-standard.module#AssessmentStandardPageModule' },
  { path: 'assessment-details', loadChildren: './student-assessment/assessment-details/assessment-details.module#AssessmentDetailsPageModule' },
  { path: 'student-assessment-content', loadChildren: './student-assessment/student-assessment-content/student-assessment-content.module#StudentAssessmentContentPageModule' },
  { path: 'itc-story-a-day-standard', loadChildren: './intheclassroom/itc-story-a-day/itc-story-a-day-standard/itc-story-a-day-standard.module#ItcStoryADayStandardPageModule' },
  { path: 'student-source-section', loadChildren: './role-section/student-source-section/student-source-section.module#StudentSourceSectionPageModule' },
  { path: 'single-itc-story-a-day', loadChildren: './intheclassroom/itc-story-a-day/single-itc-story-a-day/single-itc-story-a-day.module#SingleItcStoryADayPageModule' },
  { path: 'pdfviwer', loadChildren: './pdfviwer/pdfviwer.module#PdfviwerPageModule' },
  

];
 
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
