import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import { defineCustomElements } from '@teamhive/lottie-player/loader';  
import { AnimationModalPage } from './animation-modal.page';

export function playerFactory()
{
  return player
}
defineCustomElements(window)
const routes: Routes = [
  {
    path: '',
    component: AnimationModalPage
  }
];

@NgModule({
  imports: [
    CommonModule,
    LottieModule.forRoot({player:playerFactory}),
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes)
  ],
  declarations: [AnimationModalPage],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AnimationModalPageModule {}
