import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  LoadingController,
  ToastController,
  AlertController,
  Platform
} from '@ionic/angular';
//import { DatabaseHelperService } from '../database/database-helper.service';

import { Storage } from '@ionic/storage';
import { UserResponse } from '../models/UserResponse';
import { Login } from '../models/Login';
import { File } from '@ionic-native/file/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { SaveAttendanceDetails } from '../models/SaveAttendanceDetails';
import { errorComparator } from 'node_modules/tslint/lib/verify/lintError';
import * as moment from 'moment';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import {Subject} from 'rxjs';


 //const apiUrl = 'http://13.232.223.227/RPGServices/'; // test working
 //const fileUploadURL = 'http://13.232.223.227/';  //test
 const fileUploadURL = 'http://13.234.210.84/api/FileUpload'
 const fileDownloadURL = 'https://rpgf-bucket.s3.ap-south-1.amazonaws.com/'  //prod
//const apiUrl = 'http://13.234.210.84/RPGServices/'; // new prod
const apiUrl = 'https://patapi.pehlayakshar.org/RPGServices/' //New Prod with Domain
//const LMSAPIURL = "http://139.59.74.142:81/api/";
const LMSAPIURL = "https://palms.pehlayakshar.org/api/"
//const LMSAPIURL = "http://139.59.74.142:89/api/";  //Working
//const apiUrl = 'https://patapi.pehlayakshar.org/RPGServices/' //New Prod with Domain
//const lmsURLtest='http://139.59.74.142:81/'
const appVersion = '1.2.27'; //1.1.8

const pdfViwer = "https://docs.google.com/gview?embedded=true&url="


declare var navigator: any;
declare var Connection: any;

@Injectable({
  providedIn: 'root'
})

// tslint:disable: no-string-literal
// tslint:disable: no-debugger

export class UserService {
  // appVersion = '1.1.3';
  fileUrl = fileDownloadURL;
  events=new Subject<any>();
  pdfViwer = "https://docs.google.com/gview?embedded=true&url="
  EventPageCategory = {
    "MainPage": "Main Page",
    "AdvanceWorksheetPage": "Advance Worksheet Page",
    "BuildYourselfPage": "Build Yourself Page",
    "BuildYourselfListenPage": "Build Yourself Listen Page",
    "BuildYourselfLetsPromisePage": "Build Yourself Lets Promise Page",
    "BuildYourselfSinglePromisePage": "Build Yourself Single PromisePage",
    "BuildYourselfLetsReadPage": "Build Yourself Lets Read Page",
    "BuildYourselfLetsWatchPage": "Build Yourself Lets Watch Page",
    "BuildYourselfLetsWinPage": "Build Yourself Lets Win Page",
    "ContestsPage": "Contests Page",
    "DDVideosPage": "DD Videos Pages",
    "IntermediateWorksheetPage": "Intermediate Worksheet Page",
    "InTheClassroomPage": "In The Classroom Page",
    "InTheClassroomActivitiesPage": "In The Classroom Activities Page",
    "InTheClassroomSingaleActivityPage": "In The Classroom Singale Activity Page",
    "InTheClassroomEnergizersPage": "In The Classroom Energizers Page",
    "InTheClassroomSingleEnergizersPage": "In The Classroom Single Energizers Page",
    "InTheClassroomStoryADayPage": "In The Classroom Story A Day Page",
    "InTheClassroomLetsDoItPage": "In The Classroom Lets Do It Page",
    "InTheClassroomSingleLetsDoItPage": "In The Classroom Single Lets Do It Page",
    "InTheClassroomSingleLetsDoItSelectPage": "In The Classroom Singale Lets Do It Select Page",
    "InTheClassroomLetsDoItPWAPage": "In The Classroom Lets Do It PWA Page",
    "InTheClassroomSingleLetsDoItPWAPage": "In The Classroom Single Lets Do It PWA Page",
    "InTheClassroomSingleSelectLetsDoItPWAPage": "In The Classroom Single Select Lets Do It PWA Page",
    "InTheClassroomLetsListenPage": "In The Classroom Lets Listen Page",
    "InTheClassroomLetsPromisePage": "In The Classroom Lets Promise Page",
    "InTheClassroomSinglePromisePage": "In The Classroom Single Promise Page",
    "InTheClassroomLetsReadPage": "In The Classroom Lets Read Page",
    "InTheClassroomLetsWatchPage": "In The Classroom Lets Watch Page",
    "TecherDeskPostCommentPage": "Techer Desk Post Comment Page",
    "TeacherDeskPostDetailsPage": "Teacher Desk Post Details Page",
    "BuildYourselfQuizPage": "Build Yourself Quiz Page",
    "BuildYourselfArticlePage": "Build Yourself Article Page",
    "BuildYourselfSingleArticlePage": "Build Yourself Single Article Page",
    "VideoPage": "Video Page",
    "StudentAdvancePage": "Student Advance Page",
    "StudentBeginnerPage": "Student Beginner Page",
    "StudentIntermediatePage": "Student Intermediate Page",
    "TeacherDeskPostPage": "Teacher Desk Post Page",
    "TeacherOfTheMonthPage": "Teacher Of The Month Page",
    "LMSResourcePage": "LMS Resource Page",
    "StudentAssessment":"Sttudent Assessment"

  }
  headers: any;
  index = 0;
  assetDirectory = this.file.applicationDirectory;
  path: any; //This variable is being used to read path of language file and is set with value in readLang method
  // path = this.file.applicationDirectory + 'www/assets/';
  customer: any = [];
  loginData
  syncCallPromise: any;
  isRejected = 0;
  sessionToken = ""
  rpgId;
  guestMessage = 'Hi Guest! You will be able to view this section. Only the teachers registered with the Pehlay Akshar Program can post here.'
  constructor(
    private router: Router,
    private http: HttpClient,
    public loadingController: LoadingController,
    public sharedPrefrence: Storage,
    //public dbHelper: DatabaseHelperService,
    private toastCtrl: ToastController,
    private platform: Platform,
    public file: File,
    private fcm: FCM,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private ga: GoogleAnalytics
  ) {
    // this.fcm.subscribeToTopic('topicSubscription').then((d) => {});
    this.sharedPrefrence.get('loginData').then((lD) => {
      if (lD !== null || undefined) {
        this.sessionToken = lD.SessionToken;
        this.rpgId = lD.RpgId
        this.headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Origin', '*')
          .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
          .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
          .set('SessionToken', lD.SessionToken);
      }
    });
    this.sharedPrefrence.get('language').then((l) => {
      if (l !== null || undefined) {
        // console.log(l);
      } else {
        this.sharedPrefrence.set('language', 'english');
      }
    });
  }



  getApiUrl() {
    return apiUrl;
  }

  getAppVersion() {
    return appVersion;
  }

  trackEvent(category, action, label, value = 0) {
    this.ga.trackEvent(category, action, label, value).then(() => { })
  }

  setUserId(userId) {
    this.ga.setUserId(userId)
  }

  setLoginData(loginData){
    this.loginData = loginData
  }

  readLang() {
    this.http.get("assets/english.txt").subscribe(res=>{
      console.log(res);
      this.sharedPrefrence.set('english', res);
    })
    // if(this.platform.is('android') || this.platform.is('ios')){
    //   this.path = this.file.applicationDirectory + 'www/assets/'
    //   if (this.file.checkFile(this.path, 'hindi.txt')) {
    //     // console.log('checkfile');
    //     this.file.readAsText(this.path, 'hindi.txt').then((data) => {
    //       const d = JSON.parse(data);
    //       this.sharedPrefrence.set('hindi', d);
    //     });
    //   } else {
    //     console.log('hindi.txt not found.');
    //   }
    //   if (this.file.checkFile(this.path, 'english.txt')) {
  
    //     this.file.readAsText(this.path, 'english.txt').then((data) => {
    //       // console.log(data);
    //       const d = JSON.parse(data);
    //       this.sharedPrefrence.set('english', d);
    //     });
    //   } else {
    //     console.log('english.txt not found.');
    //   }
    // }else{
    //   this.http.get("assets/english.txt").subscribe(res=>{
    //     console.log(res);
    //     this.sharedPrefrence.set('english', res);
    //   })
    // }
   
  }

  getDDVideos() {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          apiUrl + 'Resource/GetDDVideos').subscribe((response: any) => {
            resolve(response.Results);
          }, (err) => {
            alert('An error has occured in fetching DD Videos: ' + JSON.stringify(err));
            reject(err);
          });
    });
  }

  getResource() {
    return new Promise((resolve, reject) => {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(
            apiUrl + 'Resource/GetResources?RpgId=' + lgData.RpgId, {
            headers: this.headers
          }).subscribe((response: any) => {
            resolve(response.Results);
          }, (err) => {
            alert('An error has occured in fetching data: ' + JSON.stringify(err));
            reject(err);
          });
      });
    });
  }

  getResourceByType(resourceType,targetAudienceType,Category) {
    return new Promise((resolve, reject) => {
      let url;
      if(resourceType&&targetAudienceType&&Category){
        url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType+"&resourceType="+resourceType+"&Category="+Category

      }else if(resourceType && targetAudienceType){
        url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType+"&resourceType="+resourceType

      }else {
        url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType

      }
      // if(targetAudienceType){
      //   url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType
      // }else if(resourceType && targetAudienceType){
      //   url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType+"&resourceType="+resourceType

      // }else if(resourceType&&targetAudienceType&&Category){
      //   url = 'Resource/GetResourcesByCategoryAndType?TargetAudienceType='+targetAudienceType+"&resourceType="+resourceType+"&Category="+Category

      // }

      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(
            apiUrl + url +'&AdminId=' + lgData.RpgId, {
            headers: this.headers
          }).subscribe((response: any) => {
            resolve(response.Results);
          }, (err) => {
            alert('An error has occured in fetching data: ' + JSON.stringify(err));
            reject(err);
          });
      });
    });
  }

  studentContent() {
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.sharedPrefrence.get('loginData').then((lgData) => {
            this.http
              .get(
                apiUrl + 'StudentContentDetails/GetStudentContentDetails?RpgId=' + lgData.RpgId, {
                headers: this.headers
              }).subscribe((response: any) => {
                resolve(response.Results);
                loadEl.dismiss();
              }, (err) => {
                alert('An error has occured in fetching DD data: ' + JSON.stringify(err));
                reject(err);
              });
          });
        });
    });

  }

  activityDetails(id) {
    return new Promise((resolve, reject) => {

      this.http
        .get(
          apiUrl + 'ActivityDetails/GetActivityDetailsById?ResourceId=' + id, {
          headers: this.headers
        }).subscribe((response: any) => {
          resolve(response.Result);
        }, (err) => {
          alert('An error has occured in fetching data: ' + JSON.stringify(err));
          reject(err);
        });

    });
  }

  activityDetailsByResourceId(resourceId) {
    return new Promise((resolve, reject) => {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        
         this.http
         .get(
           apiUrl + 'ActivityDetails/GetActivityDetailsByResourceId?RpgId=' + lgData.RpgId +'&resourceId='+resourceId, {
           headers: this.headers
         }).subscribe((response: any) => {
           resolve(response.Results);
         }, (err) => {
           alert('An error has occured in fetching data: ' + JSON.stringify(err));
           reject(err);
         });
        
        })
     

    });
  }

  getCoordinatorCohortsMapping() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(
            apiUrl +
            'CoordinatorCohortsMapping/GetCoordinatorCohortsMappings?RpgId=' +
            lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('CoordinatorCohortsMapping').then(
              //   () => {
              //     this.dbHelper
              //       .createTableByName('CoordinatorCohortsMapping')
              //       .then(
              //         () => {
              //           this.dbHelper
              //             .insertCoordinatorCohortsMapping(data['Results'])
              //             .then(
              //               () => {
              //                 resolve(true);
              //               },
              //               (err) => {
              //                 reject(err);
              //               }
              //             );
              //         },
              //         (err) => {
              //           reject(err);
              //         }
              //       );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
              resolve(data);

            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  addCohortForFacilitator(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          for (let i = 0; i < req.length; i++) {
            req[i].FacilitatorId = lgData.RpgId;
          }
          loadEl.dismiss();
          this.http.post(apiUrl + 'FacilitatorCohortsMapping/AddFacilitatorCohortsMapping', JSON.stringify(req), { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })

  }

  addCohortForCoordinator(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          for (let i = 0; i < req.length; i++) {
            req[i].CoordinatorId = lgData.RpgId;
          }
          loadEl.dismiss();
          this.http.post(apiUrl + 'CoordinatorCohortsMapping/AddCoordinatorCohortsMapping', JSON.stringify(req), { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })

  }

  getPrograms() {
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.sharedPrefrence.get('loginData').then((lgData) => {
            this.http
              .get(
                apiUrl +
                'ProgramDetails/GetPrograms?RpgId=' + lgData.RpgId,
                {
                  headers: this.headers
                }
              )
              .subscribe(
                (data) => {
                  // we've got back the raw data, now generate the core schedule data
                  // and save the data for later reference

                  loadEl.dismiss();
                  resolve(data);
                },
                (err) => {
                  console.log(err);
                  loadEl.dismiss();
                  reject(err);
                  this.presentToast(err);
                }
              );
          });
        });
    });
  }

  getProgramCohortList(programId) {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.sharedPrefrence.get('loginData').then((lgData) => {
            this.http
              .get(
                apiUrl +
                'CohortDetails/GetCohortsForSpecificProgram?RpgId=' + lgData.RpgId + '&ProgramId=' + programId,
                {
                  headers: this.headers
                }
              )
              .subscribe(
                (data) => {
                  // we've got back the raw data, now generate the core schedule data
                  // and save the data for later reference

                  loadEl.dismiss();
                  resolve(data);
                },
                (err) => {
                  console.log(err);
                  loadEl.dismiss();
                  reject(err);
                  this.presentToast(err);
                }
              );
          });
        });
    });
  }

  deleteCohortForFacilitator(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          req.AdminId = lgData.RpgId;
          req.FacilitatorId = lgData.RpgId;
          loadEl.dismiss();
          // console.log(JSON.stringify(req));
          this.http.post(apiUrl + 'FacilitatorCohortsMapping/DeleteFacilitatorCohortsMappingById', JSON.stringify(req), { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })

  }

  deleteCohortForCoordinator(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          req.AdminId = lgData.RpgId;
          req.CoordinatorId = lgData.RpgId;
          loadEl.dismiss();
          // console.log(JSON.stringify(req));
          this.http.post(apiUrl + 'CoordinatorCohortsMapping/DeleteCoordinatorCohortsMappingById', JSON.stringify(req), { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })
  }

  getCohortsFacilitatorMapping() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.sharedPrefrence.get('loginData').then((lgData) => {
            this.http
              .get(
                apiUrl +
                'FacilitatorCohortsMapping/GetFacilitatorCohortsMappings?RpgId=' +
                lgData.RpgId,
                {
                  headers: this.headers
                }
              )
              .subscribe(
                (data) => {
                  // we've got back the raw data, now generate the core schedule data
                  // and save the data for later reference
                  loadEl.dismiss();
                  resolve(data);
                },
                (err) => {
                  console.log(err);
                  loadEl.dismiss();
                  reject(err);
                  this.presentToast(err);
                }
              );
          });
        });
    });

  }

  getRoleTypeMapping() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(
            apiUrl +
            'RoleTypeMapping/GetRoleTypeMappings?RpgId=' +
            lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('RoleTypeMapping').then(
              //   () => {
              //     this.dbHelper
              //       .createTableByName('RoleTypeMapping')
              //       .then(
              //         () => {
              //           this.dbHelper
              //             .insertRoleTypeMapping(data['Results'])
              //             .then(
              //               () => {
              //                 resolve(true);
              //               },
              //               (err) => {
              //                 reject(err);
              //               }
              //             );
              //         },
              //         (err) => {
              //           reject(err);
              //         }
              //       );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getFacilitatorCohortsMapping() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(
            apiUrl +
            'FacilitatorCohortsMapping/GetFacilitatorCohortsMappings?RpgId=' +
            lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('FacilitatorCohortsMapping').then(
              //   () => {
              //     this.dbHelper
              //       .createTableByName('FacilitatorCohortsMapping')
              //       .then(
              //         () => {
              //           this.dbHelper
              //             .insertFacilitatorCohortsMapping(data['Results'])
              //             .then(
              //               () => {
              //                 resolve(true);
              //               },
              //               (err) => {
              //                 reject(err);
              //               }
              //             );
              //         },
              //         (err) => {
              //           reject(err);
              //         }
              //       );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getScheduleDetails() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(
            apiUrl + 'ScheduleDetails/GetScheduleDetails?RpgId=' + lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('ScheduleDetails').then(
              //   () => {
              //     this.dbHelper.createTableByName('ScheduleDetails').then(
              //       () => {
              //         this.dbHelper.insertScheduleDetails(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getQuizes() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(apiUrl + 'Question/GetQuestions?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('question').then(
              //   () => {
              //     this.dbHelper.createTableByName('question').then(
              //       () => {
              //         this.dbHelper.insertQuestion(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }
  getQuizesDetails() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(apiUrl + 'Quizdetail/GetQuizdetails?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('quizdetails').then(
              //   () => {
              //     this.dbHelper.createTableByName('quizdetails').then(
              //       () => {
              //         this.dbHelper.insertQuizDetails(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getResources() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(apiUrl + 'Resource/GetResources?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('resource').then(
              //   () => {
              //     this.dbHelper.createTableByName('resource').then(
              //       () => {
              //         this.dbHelper.insertResource(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getArticles() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(apiUrl + 'Article/GetArticles?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('articles').then(
              //   () => {
              //     this.dbHelper.createTableByName('articles').then(
              //       () => {
              //         this.dbHelper.insertArticles(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getLocation() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'Location/GetLocations?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              resolve(data);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getSchoolData() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'Schooldata/GetSchooldatas?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              resolve(data);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getTeachersData() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'Teachersdata/GetTeachersData?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              resolve(data);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getUserData() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.

      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        // }
        // else{
        //   reject("Internet not available");
        // }
        this.http
          .get(apiUrl + 'Userrespon/GetUserResponses?rpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('userresponses').then(
              //   () => {
              //     this.dbHelper.createTableByName('userresponses').then(
              //       () => {
              //         this.dbHelper
              //           .insertAllUserResponses(data['Results'])
              //           .then(
              //             () => {
              //               resolve(true);
              //             },
              //             (err) => {
              //               reject(err);
              //             }
              //           );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  getSyncPromiseList(userResponseList: any) {
    debugger

    const promiseList = [];

    if (userResponseList.length > 0) {
      userResponseList.forEach((element) => {
        debugger
        promiseList.push(this.syncCall(element));
      });
    }

    return promiseList;
  }

  syncAll(userResponseList: any) {
    debugger
    this.isRejected = 0;
    return new Promise((resolve, reject) => {
      // if(this.checkInternetConnection())
      // {
      // }
      // else{
      //   reject("Internet not available");
      // }

      Promise.all(this.getSyncPromiseList(userResponseList))
        .then((value) => {
          debugger
          value.forEach((element) => {
            debugger
            if (element === false) {
              reject();
            }
          });
          resolve(true);
        })
        .catch((err) => {
          reject();
        });
    });
  }

  // async syncAlert() {
  //   const alert = await this.alertCtrl.create({
  //     message: '<strong>App Name</strong> <br> Please sync the data.',
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         handler: () => { }
  //       },
  //       {
  //         text: 'Okay',
  //         handler: () => {
  //           this.router.navigateByUrl('/syncview');
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  // async notifyAlert(data, showMsg) {
  //   const alert = await this.alertCtrl.create({
  //     message:
  //       '<strong>' +
  //       data.title +
  //       '</strong> <br>' +
  //       data.body +
  //       '.<br>' +
  //       showMsg,
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         handler: () => {
  //           this.sharedPrefrence.remove('notificationData');
  //         }
  //       },
  //       {
  //         text: 'Okay',
  //         handler: () => {
  //           this.sharedPrefrence.remove('notificationData');
  //           this.router.navigateByUrl('/syncview');
  //         }
  //       }
  //     ]
  //   });

  //   await alert.present();
  // }

  async notifyAlert2(data, showMsg) {
    const alert = await this.alertCtrl.create({
      message:
        '<strong>' +
        data.title +
        '</strong> <br>' +
        data.body +
        '.<br>' +
        showMsg,
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            // this.router.navigateByUrl(data.path);
            this.sharedPrefrence.remove('notificationData');
          }
        }
      ]
    });

    await alert.present();
  }

  syncCall(userResponse: UserResponse) {


    return new Promise((resolve, reject) => {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        this.http
          .post(
            apiUrl + 'Userrespon/CreateUserResponse',
            JSON.stringify(userResponse),
            { headers: this.headers }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference


              // if (userResponse.Type === 'Quiz') {
              //   this.dbHelper.updateSyncData(userResponse);
              // } else {
              //   this.dbHelper.updateOtherSyncData(userResponse);
              // }

              // this.syncCall(userResponse);
              resolve(true);
            },
            (err) => {
              this.index = 0;

              if (this.isRejected === 0) {
                reject(false);
                this.isRejected = 1;
              }
              // alert('Error!!! \nPlease try again.');
              // this.sharedPrefrence.get('language').then((lang) => {
              //   this.sharedPrefrence.get(lang).then((l) => {
              //     alert(l.user_service.error);
              //   });
              // });
            }
          );
        // }
        // else{

        //   reject(false);
        //   // alert('Error!!! \nPlease try again.');
        //   this.sharedPrefrence.get('language').then((lang) => {
        //     this.sharedPrefrence.get(lang).then((l) => {
        //       alert(l.user_service.error);
        //     });
        //   });
        // }
      });
    });
  }

  syncCallDemo(userResponseList: any) {


    if (userResponseList.length > 0) {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .post(
            apiUrl + 'Userrespon/CreateUserResponse',
            JSON.stringify(userResponseList[this.index]),
            { headers: this.headers }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference


              // if (userResponseList[this.index].Type === 'Quiz') {
              //   this.dbHelper.updateSyncData(userResponseList[this.index]);
              // } else {
              //   this.dbHelper.updateOtherSyncData(userResponseList[this.index]);
              // }
              this.index = this.index + 1;
              if (this.index < userResponseList.length) {
                this.syncCallDemo(userResponseList);
              } else {
                this.index = 0;

                return new Promise((resolve, reject) => {
                  resolve(true);
                });
                this.loadingController.dismiss();
                alert('Data synced successfully');
                this.refreshCall(0);
              }
            },
            (err) => {
              this.index = 0;
              return new Promise((resolve, reject) => {
                reject(false);
              });
              alert('Error!!! \nPlease try again.');
            }
          );
      });
    } else {
      // alert('Data synced successfully.');
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          alert(l.user_service.data_sync);
        });
      });
      return new Promise((resolve, reject) => {
        resolve(true);
      });
      this.refreshCall(0);
    }
  }

  syncData(logoutFlag) {
    debugger
    return new Promise((resolve, reject) => {
      this.loadingController
        .create({
          message: 'Please wait... Syncing Data'
        })
        .then((loadEl) => {
          loadEl.present();


          this.sharedPrefrence.get('loginData').then((lgData) => {
            debugger
            this.loginData = lgData
            // this.dbHelper
            //   .getSyncData()
            //   .then((data) => {
            //     debugger
            //     return this.syncAll(data);
            //   })
            //   .then((data) => {
            //     debugger
            //     if (data === true) {
            //       loadEl.dismiss();
            //       if (logoutFlag) {
            //         this.dbHelper.deleteRecord(1);
            //         this.sharedPrefrence.set("LmsData",this.loginData).then((data)=>{
            //           console.log("data",data)
            //           this.router.navigateByUrl('/home');

            //         })

            //       }
            //       resolve(true);
            //     } else {
            //       loadEl.dismiss();

            //       // alert('Error!!! \nPlease try again.');
            //       this.sharedPrefrence.get('language').then((lang) => {
            //         this.sharedPrefrence.get(lang).then((l) => {
            //           alert(l.user_service.error);
            //         });
            //       });
            //       reject();
            //     }
            //   })
            //   .catch((error) => {
            //     loadEl.dismiss();

            //     // alert('Error!!! \nPlease try again.');
            //     this.sharedPrefrence.get('language').then((lang) => {
            //       this.sharedPrefrence.get(lang).then((l) => {
            //         alert(l.user_service.error);
            //       });
            //     });
            //     reject();
            //   });
          });
        });
    });
  }

  checkInternetConnection() {
    let isOn;
    // let states = {};
    // states[Connection.UNKNOWN] = 'Unknown connection';
    // states[Connection.ETHERNET] = 'Ethernet connection';
    // states[Connection.WIFI] = 'WiFi connection';
    // states[Connection.CELL_2G] = 'Cell 2G connection';
    // states[Connection.CELL_3G] = 'Cell 3G connection';
    // states[Connection.CELL_4G] = 'Cell 4G connection';
    // states[Connection.CELL] = 'Cell generic connection';
    // states[Connection.NONE] = 'No network connection';

    if (navigator.connection.type !== 'No network connection' && navigator.connection.type !== 'none') {
      isOn = true;
    } else {
      isOn = false;
    }

    return isOn;
  }

  checkRefreshCall(step) {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      this.sharedPrefrence
        .get(step)
        .then((data) => {
          if (data === 1) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch((error) => {
          resolve(true);
        });
    });
  }

  /*
   Method Name: refreshCall()
   Arguments: userId and sessionToken
   Return Data:
   Description: On login getting all the data related to user and his/her role
   */
  async refreshCall(isLogin) {
    //this.router.navigateByUrl('/syncview', isLogin);
    // this.loadingController
    //   .create({
    //     message: `
    //       Please wait...
    //      <ul>
    //        <li> Syncing Data: &#10004;</li>
    //        <li> Fetching Articles: </li>
    //        <li> Fetching Resources: </li>
    //        <li> Fetching Quiz:</li>
    //      </ul>
    //    `
    //   })
    //   .then((modalEl) => {
    //     modalEl.present();
    //

    //     this.sharedPrefrence.get('loginData').then((lgData) => {
    //       this.dbHelper
    //         .getSyncData()
    //         .then((data) => {
    //           return this.syncAll(data);
    //         })
    //         .then((data) => {
    //           if (data == true) {
    //             this.dbHelper.deleteRecord(isLogin);

    //             modalEl.message = `
    //               Please wait...
    //              <ul>
    //                <li> Syncing Data: &#10004;</li>
    //                <li> Fetching Articles: </li>
    //                <li> Fetching Resources: </li>
    //                <li> Fetching Quiz:</li>
    //              </ul>
    //            `;
    //             this.checkRefreshCall('articles')
    //               .then((data) => {
    //

    //                 if (data == false) {
    //                   return this.checkRefreshCall('articles');
    //                 } else {
    //                   return this.getArticles();
    //                 }
    //               })
    //               .then((data) => {
    //
    //                 //modalEl.componentProps.articlesStatus = "Done!!!"
    //                 modalEl.message = `
    //                 Please wait...
    //                <ul>
    //                  <li> Syncing Data: &#10004;</li>
    //                  <li> Fetching Articles: &#10004;</li>
    //                  <li> Fetching Resources: </li>
    //                  <li> Fetching Quiz:</li>
    //                </ul>
    //              `;
    //                 this.sharedPrefrence.set('articles', 1);

    //                 if (data == false) {
    //                   return this.checkRefreshCall('resource');
    //                 } else {
    //                   if (data !== undefined) {
    //                     this.dbHelper.insertArticles(data['Results']);
    //                   }

    //                   return this.getResources();
    //                 }
    //               })
    //               .then((data) => {
    //
    //                 modalEl.message = `
    //                 Please wait...
    //                <ul>
    //                  <li> Syncing Data: &#10004;</li>
    //                  <li> Fetching Articles: &#10004;</li>
    //                  <li> Fetching Resources: &#10004;</li>
    //                  <li> Fetching Quiz:</li>
    //                </ul>
    //              `;
    //                 //modalEl.componentProps.resourceStatus = "Done!!!"
    //                 this.sharedPrefrence.set('resource', 1);

    //                 if (data == false) {
    //                   return this.checkRefreshCall('quizeDetails');
    //                 } else {
    //                   if (data !== undefined) {
    //                     this.dbHelper.insertResource(data['Results']);
    //                   }

    //                   return this.getQuizesDetails();
    //                 }
    //               })
    //               .then((data) => {
    //
    //                 modalEl.message = `
    //                 Please wait...
    //                <ul>
    //                  <li> Syncing Data: &#10004;</li>
    //                  <li> Fetching Articles: &#10004;</li>
    //                  <li> Fetching Resources: &#10004;</li>
    //                  <li> Fetching Quiz:</li>
    //                </ul>
    //              `;
    //                 //this.sharedPrefrence.set('quizeDetails', 1);

    //                 if (data == false) {
    //                   return this.checkRefreshCall('quizes');
    //                 } else {
    //                   if (data !== undefined) {
    //                     this.dbHelper.insertQuizDetails(data['Results']);
    //                   }

    //                   return this.getQuizes();
    //                 }
    //               })
    //               .then((data) => {
    //
    //                 modalEl.message = `
    //                 Please wait...
    //                <ul>
    //                  <li> Syncing Data: &#10004;</li>
    //                  <li> Fetching Articles: &#10004;</li>
    //                  <li> Fetching Resources: &#10004;</li>
    //                  <li> Fetching Quiz:</li>
    //                </ul>
    //              `;
    //                 this.sharedPrefrence.set('quizes', 1);

    //                 if (data == false) {
    //                   return this.checkRefreshCall('userResponse');
    //                 } else {
    //                   if (data !== undefined) {
    //                     this.dbHelper.insertQuestion(data['Results']);
    //                   }

    //                   return this.getUserData();
    //                 }
    //               })
    //               .then((data) => {
    //
    //                 modalEl.message = `
    //                 Please wait...
    //                <ul>
    //                  <li> Syncing Data: &#10004;</li>
    //                  <li> Fetching Articles: &#10004;</li>
    //                  <li> Fetching Resources: &#10004;</li>
    //                  <li> Fetching Quiz: &#10004;</li>
    //                </ul>
    //              `;
    //                 this.sharedPrefrence.set('userResponse', 1);
    //                 if (data == false) {
    //                 } else {
    //                   if (data !== undefined) {
    //                     this.dbHelper.insertAllUserResponses(data['Results']);
    //                   }
    //                 }

    //                 modalEl.dismiss();
    //                 this.sharedPrefrence.set('articles', 0);
    //                 this.sharedPrefrence.set('resource', 0);
    //                 this.sharedPrefrence.set('quizeDetails', 0);
    //                 this.sharedPrefrence.set('quizes', 0);
    //                 this.sharedPrefrence.set('userResponse', 0);

    //                 this.sharedPrefrence.set('refresh', 1);

    //                 // if (lgData.isFirstLogin === 1) {
    //                 this.router.navigateByUrl('/main');
    //                 // } else {
    //                 //   this.router.navigateByUrl(
    //                 //     '/settings',
    //                 //     lgData.isFirstLogin
    //                 //   );
    //                 // }
    //               })
    //               .catch((err) => {
    //                 modalEl.dismiss();
    //                 this.sharedPrefrence.set('refresh', 0);
    //                 this.router.navigateByUrl('/home');
    //                 // alert('Error!!! \nPlease try again.');
    //                 this.sharedPrefrence.get('language').then((lang) => {
    //                   this.sharedPrefrence.get(lang).then((l) => {
    //                     console.log('main: ' + l);
    //                     alert(l.alert.user_service.error);
    //                   });
    //                 });
    //               });
    //           } else {
    //             modalEl.dismiss();
    //             this.modalCtrl.dismiss();
    //             this.sharedPrefrence.set('refresh', 0);
    //             this.router.navigateByUrl('/home');
    //             // alert('Error!!! \nPlease try again.');
    //             this.sharedPrefrence.get('language').then((lang) => {
    //               this.sharedPrefrence.get(lang).then((l) => {
    //                 console.log('main: ' + l);
    //                 alert(l.alert.user_service.error);
    //               });
    //             });
    //           }
    //         });
    //     });
    //   });
  }

  /*
    Method Name: login()
    Arguments: MobileNumber and Password
    Description: For login to the app on the basis of their role
  */
  async login(no, pwd) {
    if (this.checkInternetConnection()) {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          const loginData = new Login();
          loginData.Password = pwd;
          loginData.Mobile = no;
          loginData.loginType = 'App';
          this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
            .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
          this.http
            .post(apiUrl + 'Login/CreateLogin', JSON.stringify(loginData), {
              headers: this.headers
            })
            .subscribe(
              (data) => {
                if (data['success'] === false) {
                  alert(data['error']);
                  loadEl.dismiss();
                } else {
                  this.sharedPrefrence.set('loginData', data['Result']);

                  this.headers = new HttpHeaders()
                    .set('Content-Type', 'application/json')
                    .set('Access-Control-Allow-Origin', '*')
                    .set(
                      'Access-Control-Allow-Methods',
                      'POST, GET, OPTIONS, PUT'
                    )
                    .set(
                      'Access-Control-Allow-Methods',
                      'POST, GET, OPTIONS, PUT'
                    )
                    .set('SessionToken', data['Result'].SessionToken);
                //  if (data['Result'].Role === 'Guest Student') {
                    this.router.navigateByUrl('/main');
                  //} else {
                  //   if (data['Result'].isFirstLogin === 0) {
                  //     this.changePassword(pwd, pwd).then((res) => {
                  //       this.refreshCall(0);
                  //     }, (err) => { alert(err); });
                  //   } else {
                  //     this.refreshCall(0);
                  //   }
                  // }
                }
                loadEl.dismiss();
              },
              (err) => {
                // var login = new Login();

                // login.RpgId = 5;
                // this.sharedPrefrence.set('loginData', login);
                console.log(err);
                loadEl.dismiss();
              });
        });
    } else {
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          this.presentToast(l.user_service.no_internet);
        });
      });
    }
  }



  externalLogin(req) {
    if (this.checkInternetConnection()) {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.http.post(apiUrl + 'Login/ExternalLogin', req, { observe: 'response' })
            .subscribe((data: any) => {
              console.log(data);
              // tslint:disable-next-line: no-debugger
              loadEl.dismiss();
              // alert(JSON.stringify(data));
             
              if (!data.body.success && data.body.error === 'No Record found...') {
                // alert(data.body.error);
                this.router.navigate(['/register']);
              } else {
                data.body['Result'].isFirstLogin = 1;
                this.sharedPrefrence.set('loginData', data.body['Result']);

                this.headers = new HttpHeaders()
                  .set('Content-Type', 'application/json')
                  .set('Access-Control-Allow-Origin', '*')
                  .set(
                    'Access-Control-Allow-Methods',
                    'POST, GET, OPTIONS, PUT'
                  )
                  .set(
                    'Access-Control-Allow-Methods',
                    'POST, GET, OPTIONS, PUT'
                  )
                  .set('SessionToken', data.body['Result'].SessionToken);
                if (data.body['Result'].Role === 'Guest Student') {
                  this.router.navigateByUrl('/levels');
                } else {
                  this.router.navigateByUrl('/main');
                }
              }
            }, error => {
              loadEl.dismiss();
              console.log(error);
              alert(JSON.stringify(error));
            });
        });
    } else {
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          this.presentToast(l.user_service.no_internet);
        });
      });
    }
  }


  register(req) {
    if (this.checkInternetConnection()) {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          const headers = new Headers();
          headers.append('Accept', 'application/json');
          headers.append('Content-Type', 'application/json');

          this.http.post(apiUrl + 'Login/RegisterUserOnApp', req, { observe: 'response' })
            .subscribe((data: any) => {
              console.log(data);
              // tslint:disable-next-line: no-debugger
              loadEl.dismiss();
              
              // alert(JSON.stringify(data));
              if (data.body.success) {
                const reqData = {
                  SecretKey: data.body['Result'].SecretKey,
                  GoogleOrFBProfile: data.body['Result'].GoogleOrFBProfile,
                };
                this.externalLogin(reqData);
              } else {
                alert('Registration Failed. Try again.');
              }

            }, error => {
              console.log(error);
              loadEl.dismiss();
              alert(JSON.stringify(error));
            });
        });
    } else {
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          this.presentToast(l.user_service.no_internet);
        });
      });
    }
  }


  directRegister(req) {
    if (this.checkInternetConnection()) {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          const headers = new Headers();
          headers.append('Accept', 'application/json');
          headers.append('Content-Type', 'application/json');

          this.http.post(apiUrl + 'Login/RegisterUserOnApp', req, { observe: 'response' })
            .subscribe((data: any) => {
              console.log(data);
              // tslint:disable-next-line: no-debugger
              loadEl.dismiss();
              
              // alert(JSON.stringify(data));
              alert(data.body.error);
              if (data.body.success) {
                this.router.navigateByUrl('/home');
              } else {
                alert('Registration Failed. Try again.');
              }

            }, error => {
              console.log(error);
              loadEl.dismiss();
              alert(JSON.stringify(error));
            });
        });
    } else {
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          this.presentToast(l.user_service.no_internet);
        });
      });
    }
  }
  /*
    Method Name: changePassword()
    Arguments: New Password
    Description: For changing password
  */
  async changePassword(currPwd, newPwd) {
    return new Promise((resolve) => {
      if (this.checkInternetConnection()) {
        this.loadingController
          .create({
            message: 'Please wait...'
          })
          .then((loadEl) => {
            loadEl.present();
            this.sharedPrefrence.get('loginData').then((lgData) => {
              const storedData = lgData;
              storedData.Password = currPwd;
              storedData.isFirstLogin = 1;
              const paramObj = { login: storedData, newPass: newPwd };
              this.http
                .post(
                  apiUrl + 'Login/ChangePassword',
                  JSON.stringify(paramObj),
                  {
                    headers: this.headers
                  }
                )
                .subscribe(
                  (data) => {
                    if (data['success'] === false) {
                      alert(data['error']);
                      loadEl.dismiss();
                      resolve(0);
                    } else {
                      storedData.Password = 'random';
                      this.sharedPrefrence.set('loginData', storedData);
                      console.log(storedData);
                      loadEl.dismiss();
                      // alert('Password changed successfully.');
                      // this.sharedPrefrence.get('language').then((lang) => {
                      //   this.sharedPrefrence.get(lang).then((l) => {
                      //     alert(l.user_service.password_change);
                      //   });
                      // });
                      // this.refreshCall();
                      resolve(1);
                    }
                  },
                  (err) => {
                    console.log(err);
                    loadEl.dismiss();
                    resolve(0);
                  }
                );
            });
          });
      } else {
        this.sharedPrefrence.get('language').then((lang) => {
          this.sharedPrefrence.get(lang).then((l) => {
            this.presentToast(l.user_service.no_internet);
          });
        });
      }
    });
  }


  async addProgram(code, role) {
    return new Promise((resolve) => {
      if (this.checkInternetConnection()) {
        this.loadingController
          .create({
            message: 'Please wait...'
          })
          .then((loadEl) => {
            loadEl.present();
            this.sharedPrefrence.get('loginData').then((lgData) => {
              const reqObj = {
                RpgId: lgData.RpgId,
                Role: role,
                ProgramCode: code
              };
              this.http
                .post(
                  apiUrl + 'UserProgramReqDetails/AssignUserToProgram',
                  JSON.stringify(reqObj),
                  {
                    headers: this.headers
                  }
                )
                .subscribe(
                  (data) => {
                    if (data['success'] === false) {
                      alert(data['error']);
                      loadEl.dismiss();
                      resolve(0);
                    } else {
                      this.sharedPrefrence.set('loginData', data['Result']);
                      this.headers = new HttpHeaders()
                        .set('Content-Type', 'application/json')
                        .set('Access-Control-Allow-Origin', '*')
                        .set(
                          'Access-Control-Allow-Methods',
                          'POST, GET, OPTIONS, PUT'
                        )
                        .set(
                          'Access-Control-Allow-Methods',
                          'POST, GET, OPTIONS, PUT'
                        )
                        .set('SessionToken', data['Result'].SessionToken);
                      loadEl.dismiss();
                      resolve(1);
                    }
                  },
                  (err) => {
                    console.log(err);
                    alert(err);
                    loadEl.dismiss();
                    resolve(0);
                  }
                );
            });
          });
      } else {
        this.sharedPrefrence.get('language').then((lang) => {
          this.sharedPrefrence.get(lang).then((l) => {
            this.presentToast(l.user_service.no_internet);
          });
        });
      }
    });
  }

  /*
    Method Name: forgotPassword()
    Arguments: MobileNumber
    Description:
  */
  async forgotPassword(no) {
    if (this.checkInternetConnection()) {
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          const loginData = new Login();
          loginData.Mobile = no;
          // loginData.loginType = 'App';
          this.headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Access-Control-Allow-Origin', '*')
            .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
            .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
          this.http
            .post(apiUrl + 'Login/ForgotPassword', JSON.stringify(loginData), {
              headers: this.headers
            })
            .subscribe(
              (data) => {
                if (data['success'] === false) {
                  alert(data['error']);
                  loadEl.dismiss();
                  console.log(data);
                } else {
                  alert(
                    'Password reset successfully. Please Login using your Phone Number.'
                  );
                }
                loadEl.dismiss();
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
              }
            );
        });
    } else {
      this.sharedPrefrence.get('language').then((lang) => {
        this.sharedPrefrence.get(lang).then((l) => {
          this.presentToast(l.user_service.no_internet);
        });
      });
    }
  }

  /*
    Method Name: getSessionTrainingDetails()
    Arguments:
    Description:
  */
  getSessionTrainingDetails() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(
            apiUrl +
            'SessionTrainingDetails/GetSessionTrainingDetails?RpgId=' +
            lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              resolve(data);
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  /*
    Method Name: getFeedback()
    Arguments:
    Description:
  */
  getFeedback() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'FeedBack/GetFeedBacks?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('feedback').then(
              //   () => {
              //     this.dbHelper.createTableByName('feedback').then(
              //       () => {
              //         this.dbHelper.insertFeedback(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }

  /*
     Method Name: getFeedback()
     Arguments:
     Description:
   */
  getByLines() {
    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + '/ByLineDetails/GetByLineDetails?AdminId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper.dropTable('ByLine').then(
              //   () => {
              //     this.dbHelper.createTableByName('ByLine').then(
              //       () => {
              //         this.dbHelper.insertByLineDetails(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);
              reject(err);
            }
          );
      });
    });
  }


  /*
    Method Name: getContestDetails()
    Arguments:
    Description:
  */
  getContestDetails() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.loadingController
        .create({
          message: 'Please wait...'
        })
        .then((loadEl) => {
          loadEl.present();
          this.sharedPrefrence.get('loginData').then((lgData) => {
            this.http
              .get(
                apiUrl +
                'Contestdetail/GetContestdetails?RpgId=' +
                lgData.RpgId,
                {
                  headers: this.headers
                }
              )
              .subscribe(
                (data) => {
                  // we've got back the raw data, now generate the core schedule data
                  // and save the data for later reference

                  loadEl.dismiss();
                  resolve(data);
                },
                (err) => {
                  console.log(err);
                  loadEl.dismiss();
                  reject(err);
                  this.presentToast(err);
                }
              );
          });
        });
    });
  }

  checkPlayStoreVersion() {
    return new Promise((resolve, reject) => {
      if (this.checkInternetConnection()) {
        this.loadingController
          .create({
            message: 'Please wait...'
          })
          .then((loadEl) => {
            loadEl.present();
            this.sharedPrefrence.get('loginData').then((lgData) => {
              this.http
                .get(
                  apiUrl +
                  'AppVersionDetails/GetAppVersionDetailss?RpgId=' +
                  lgData.RpgId,
                  {
                    headers: this.headers
                  }
                )
                .subscribe((data) => {
                  if (data['success'] === false) {
                    console.log(data);

                    loadEl.dismiss();
                    resolve(true);
                  } else {
                    if (data['Result'].AppVersion > appVersion) {
                      this.updateAlert();
                      resolve(false);
                    }
                    loadEl.dismiss();
                    resolve(true);
                  }
                });
            });
            loadEl.dismiss();
          });
      } else {
        resolve(true);
      }
    });
  }

  onlineGetCategoryDetails(audienceType,resourceType){
    return new Promise((resolve, reject) => {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(
            apiUrl + 'CategoryDetails/GetCategories?AdminId=' + lgData.RpgId +"&targetAudience="+audienceType+"&resourceType="+resourceType,
            {
              headers: this.headers
            }
          ).subscribe(res=>{
            resolve(res);
          },(err)=>{
            reject(err)
          })
        })
    })
  }

  getCategoryDetails() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(
            apiUrl + 'CategoryDetails/GetCategories?AdminId=' + lgData.RpgId,
            {
              headers: this.headers
            }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference


              // this.dbHelper.dropTable('categorydetails').then(
              //   () => {
              //     this.dbHelper.createTableByName('categorydetails').then(
              //       () => {
              //         this.dbHelper.insertCategoryDetails(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);

              reject(err);
              this.presentToast(err);
            }
          );
      });
    });
  }

  saveAttendanceData(attendanceData) {


    return new Promise((resolve, reject) => {
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // if(this.checkInternetConnection())
        // {
        this.http
          .post(
            apiUrl + 'AttendanceDetails/SaveAttendanceDetails',
            JSON.stringify(attendanceData),
            { headers: this.headers }
          )
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              // this.dbHelper
              //   .dropTable('saveAttendanceDetailsTeacherAttendance')
              //   .then(
              //     () => {
              //       this.dbHelper
              //         .createTableByName(
              //           'saveAttendanceDetailsTeacherAttendance'
              //         )
              //         .then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //     },
              //     (err) => {
              //       reject(err);
              //     }
              //   );
            },
            (err) => {
              reject(false);
            }
          );
      });
    });
  }

  getTeachersDataForFacilitator() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        // this.dbHelper.getRoleTypeMappingData().then((rtm: any) => {
        //   const roleType = rtm.findIndex(x => x.RoleType === 'Facilitator');
        //   if (roleType !== -1) {
        //     this.http
        //       .get(apiUrl + 'Login/GetLoginDetails?RpgId=' + lgData.RpgId, {
        //         headers: this.headers
        //       })
        //       .subscribe(
        //         (data) => {
        //           // we've got back the raw data, now generate the core schedule data
        //           // and save the data for later reference


        //           this.dbHelper.dropTable('teachersData').then(
        //             () => {
        //               this.dbHelper.createTableByName('teachersData').then(
        //                 () => {
        //                   this.dbHelper
        //                     .insertTeachersDataForFacilitator(data['Results'])
        //                     .then(
        //                       () => {
        //                         resolve(true);
        //                       },
        //                       (err) => {
        //                         reject(err);
        //                       }
        //                     );
        //                 },
        //                 (err) => {
        //                   reject(err);
        //                 }
        //               );
        //             },
        //             (err) => {
        //               reject(err);
        //             }
        //           );
        //         },
        //         (err) => {
        //           console.log(err);

        //           reject(err);
        //           this.presentToast(err);
        //         }
        //       );
        //   } else {
        //     resolve({ Results: [] });
        //   }
        // });
      });
    });
  }

  getLeaderboardData() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'LeaderBoardRank/GetRankings?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference


              // this.dbHelper.dropTable('leaderboard').then(
              //   () => {
              //     this.dbHelper.createTableByName('leaderboard').then(
              //       () => {
              //         this.dbHelper
              //           .insertLeaderboardDetails(data['Results'])
              //           .then(
              //             () => {
              //               resolve(true);
              //             },
              //             (err) => {
              //               reject(err);
              //             }
              //           );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);

              reject(err);
              this.presentToast(err);
            }
          );
      });
    });
  }

  getMyPointsData() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'LeaderBoardRank/GetMyPoints?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference


              // this.dbHelper.dropTable('MyPoints').then(
              //   () => {
              //     this.dbHelper.createTableByName('MyPoints').then(
              //       () => {
              //         this.dbHelper.insertMyPointsDetails(data['Result']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);

              reject(err);
              this.presentToast(err);
            }
          );
      });
    });
  }

  getActivityDetailsData() {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.sharedPrefrence.get('loginData').then((lgData) => {
        this.http
          .get(apiUrl + 'ActivityDetails/GetActivityDetails?RpgId=' + lgData.RpgId, {
            headers: this.headers
          })
          .subscribe(
            (data) => {
              // we've got back the raw data, now generate the core schedule data
              // and save the data for later reference

              
              // this.dbHelper.dropTable('ActivityDetails').then(
              //   () => {
              //     this.dbHelper.createTableByName('ActivityDetails').then(
              //       () => {
              //         this.dbHelper.insertActivityDetails(data['Results']).then(
              //           () => {
              //             resolve(true);
              //           },
              //           (err) => {
              //             reject(err);
              //           }
              //         );
              //       },
              //       (err) => {
              //         reject(err);
              //       }
              //     );
              //   },
              //   (err) => {
              //     reject(err);
              //   }
              // );
            },
            (err) => {
              console.log(err);

              reject(err);
              this.presentToast(err);
            }
          );
      });
    });
  }

  likeTheContent(req) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + 'ResourceLikeDetails/LikeTheContent', req, { headers: this.headers }).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  getRourcesfilter(req) { 
    let url = apiUrl + "Resource/GetResourcesByCategoryAndType?TargetAudienceType=" + req.targetAudienceType + "&ResourceType=" + req.resourceType + "&Category=" + req.category+ "&AdminId=" + req.rpgId;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  getRourcesfilter2(req) { 
    let url = apiUrl + "Resource/GetResourcesByCategoryAndType?TargetAudienceType=" + req.targetAudienceType + "&ResourceType=" + req.resourceType + "&Category=" + req.category+ "&SubCategory=" + req.subCategory+ "&AdminId=" + req.rpgId;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  getRourcesfilter1(req) { 
    let url = apiUrl + "Resource/GetResourcesByCategoryAndType?ResourceType=" + req.resourceType + "&Category=" + req.category+ "&AdminId=" + req.rpgId;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  activityfilter(req) { 
    let url = apiUrl + "ActivityDetails/GetActivityDetailsByCategoryAndType?TargetAudienceType=" + req.targetAudienceType + "&ResourceType=" + req.resourceType + "&Category=" + req.category+ "&AdminId=" + req.rpgId;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  activityfilterWithoutLoader(req) { 
    let url = apiUrl + "ActivityDetails/GetActivityDetailsByCategoryAndType?TargetAudienceType=" + req.targetAudienceType + "&ResourceType=" + req.resourceType + "&Category=" + req.category+ "&AdminId=" + req.rpgId;
    return new Promise((resolve, reject) => {

      this.http.get(url,{headers:this.headers}).subscribe(res => {

        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  async updateAlert() {
    const alert = await this.alertCtrl.create({
      message:
        '<strong>New App Version Available.</strong> <br>Please Update the App.',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.syncData(false).then(
              () => {
                navigator['app'].loadUrl(
                  'https://play.google.com/store/apps/details?id=com.pehlayakshar&hl=en',
                  {
                    openExternal: true
                  }
                );
              },
              (e) => { }
            );
          }
        }
      ],
      backdropDismiss: false
    });

    await alert.present();
  }

  editProfile(profData) {

    // don't have the data yet
    return new Promise((resolve, reject) => {
      // We're using Angular HTTP provider to request the data,
      // then on the response, it'll map the JSON data to a parsed JS object.
      // Next, we process the data and resolve the promise with the new data.
      this.http
        .post(apiUrl + 'Login/UpdateLogin', JSON.stringify(profData), {
          headers: this.headers
        })
        .subscribe(
          (data) => {
            // we've got back the raw data, now generate the core schedule data
            // and save the data for later reference
            
            resolve(true);
          },
          (err) => {
            
            console.log(err);

            reject(err);
            this.presentToast(err);
          }
        );

    });
  }





  /*
    Method Name: presentToast()
    Arguments: Text
    Description: For presenting toast
  */
  async presentToast(toastData) {
    const toast = await this.toastCtrl.create({
      message: toastData,
      duration: 2000
    });
    toast.present();
  }

  async presentLongToast(toastData) {
    const toast = await this.toastCtrl.create({
      message: toastData,
      duration: 5000
    });
    toast.present();
  }





  teacherDeskPost(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          //  for(let i=0; i< req.length; i++){
          //    req[i].FacilitatorId = lgData.RpgId;
          //  }

          req.UserId = lgData.RpgId;
          req.PostDate = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
          req.AdminId = lgData.RpgId

          loadEl.dismiss();
          this.http.post(apiUrl + 'PostDetails/CreatePost', req, { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })

  }

  postComment(req) {
    return new Promise((resolve, reject) => {
      this.loadingController.create({
        message: 'Please wait'
      }).then((loadEl) => {
        loadEl.present();
        this.sharedPrefrence.get('loginData').then((lgData) => {
          //  for(let i=0; i< req.length; i++){
          //    req[i].FacilitatorId = lgData.RpgId;
          //  }

          req.UserId = lgData.RpgId;
          req.ReplyDate = moment(new Date()).format('DD-MM-YYYY HH:mm:ss');
          req.AdminId = lgData.RpgId;
          req.ContentType = "Comment";
          loadEl.dismiss();
          this.http.post(apiUrl + 'PostCommentReply/AddPostComments', req, { headers: this.headers })
            .subscribe(
              (data) => {
                if (data['success']) {
                  resolve(true);
                }
              },
              (err) => {
                console.log(err);
                loadEl.dismiss();
                reject(err);
                this.presentToast(err);
              }
            )
        })
      })
    })

  }

  getCommentDetails(req) {
    let url = apiUrl + 'PostCommentReply/GetPostCommentReplyByReplyId?ReplyId=' + req.ReplyId + '&UserId=' + req.userId;
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.headers }).subscribe((res: any) => {
        if (res.Results.length > 0) {
          res.Results.forEach((item) => {
            item.PostCustomDate = this.changeDateFormat(item.PostDate);
            item.PostAttachments.forEach((attachments) => {
              attachments.FilePath = fileDownloadURL + attachments.FilePath
            })

          })
        }
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  getCommentList(req) {
    let url = req.isLoadMore ? apiUrl + 'PostCommentReply/GetPostCommentReplyByPostID?RpgId=' + req.RpgId + '&PostId=' + req.PostId + '&timestamap=' + req.timestamap + '&isOlder=' + req.isOlder : apiUrl + 'PostCommentReply/GetPostCommentReplyByPostID?RpgId=' + req.RpgId + '&PostId=' + req.PostId
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.headers }).subscribe((res: any) => {
        if (res.Results.length > 0) {
          res.Results.forEach((item) => {
            item.PostCustomReplyDate = this.changeDateFormat(item.ReplyDate);
            item.PostAttachments.forEach((attachments) => {
              attachments.FilePath = fileDownloadURL + attachments.FilePath
            })

          })
        }
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }


  uploadPostFile(req) {
    return new Promise((resolve, reject) => {
      let postHeader = new HttpHeaders()
        .set('SessionToken', this.sessionToken);
      this.http.post(fileUploadURL, req, { headers: postHeader, responseType: 'text' }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(errorComparator)
      })
    })
  }


  getPostList(req) {
    let approveUnapproveListURL = req.postListType == "Approved" ? "PostDetails/GetPostDetails" : "PostDetails/GetListOfPostsToApprove"
    let url = req.isLoadMore ? apiUrl + approveUnapproveListURL + '?RpgId=' + req.RpgId + "&timestamap=" + req.timestamap + "&isOlder=" + req.isOlder : apiUrl + approveUnapproveListURL + '?RpgId=' + req.RpgId
    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.headers }).subscribe((res: any) => {
        if (res.Results.length > 0) {
          res.Results.forEach((item) => {
            item.PostCustomDate = this.changeDateFormat(item.PostDate);
            item.PostAttachments.forEach((attachments) => {
              attachments.FilePath = fileDownloadURL + attachments.FilePath
            })

          })
        }
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  getActivityDetails(req) {
    let url = apiUrl + "ActivityDetails/GetActivityDetailsByType?TargetAudienceType=" + req.targetAudienceType + "&ResourceType=" + req.resourceType + "&Category=" + req.category;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })

  }

  getActivityDetailsById(req) {
    let url = apiUrl + "ActivityDetails/GetActivityDetailsById?AdminId="+req.AdminId+"&ResourceId=" + req.resourceId;
    return new Promise((resolve, reject) => {
      this.showLoader()
      this.http.get(url,{headers:this.headers}).subscribe(res => {
        this.hideLoader()
        resolve(res);
      }, error => {
        this.hideLoader()
        reject(error);
      })
    })
  }

  changeDateFormat(date) {
    let splitDate = date.split("-")
    return splitDate[1] + "-" + splitDate[0] + "-" + splitDate[2];
  }

  approveRejectService(req) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + 'PostDetails/ApproveORRejectPost', req, { headers: this.headers }).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  likeCommentPost(req) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + 'PostLike/AddPostLike', req, { headers: this.headers }).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  deleteCommentPost(req) {
    return new Promise((resolve, reject) => {
      this.http.post(apiUrl + 'PostCommentReply/DeletePostCommentReplyById', req, { headers: this.headers }).subscribe(res => {
        resolve(res);
      }, error => {
        reject(error);
      })
    })
  }

  showLoader() {
    this.loadingCtrl.create({ message: "" }).then((res) => {
      res.present();
    })
  }

  hideLoader() {
    this.loadingCtrl.dismiss();
  }

  getRoleBaseOnUser(rpgId) {
    return new Promise((resolve, reject) => {
      this.http.get(apiUrl + 'RoleTypeMapping/GetDistinctRoles?RpgId=' + rpgId, { headers: this.headers }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }
  async alertPopUp(message, isAutoDismiss = true) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: ['Ok']
    });

    await alert.present();
    if (isAutoDismiss) {
      setTimeout(() => {
        alert.dismiss();
      }, 1500)
    }

  }


  getAllCourseSection(id,usertype?) {
    let reqObj = {courseid:id,usertype}
    return new Promise((resolve, reject) => {
      this.http.post(LMSAPIURL + 'getcoursedetail', reqObj, { headers: this.headers }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }

  getCompletionDetails(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'lmsaddupdateusertracking',{courseid:req.courseid,RpgId:req.rpgId,courseEnrolled:true,usertype:req.usertype},{headers:this.headers}).subscribe(res=>{
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }

  getCourseSubsection(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'getcoursesubsection',req,{headers:this.headers}).subscribe(res=>{
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }

  makeFavouriteCourse(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'lmsaddupdateuserfavourite',{courseid:req.courseid,RpgId:req.rpgId,courseEnrolled:true,usertype:req.usertype },{headers:this.headers}).subscribe(res=>{
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }
  markAsCompletedSection(req) {
    return new Promise((resolve, reject) => {
      this.http.post(LMSAPIURL + 'lmsaddupdateusercoursesection', { courseid: req.courseId, RpgId: req.rpgId, courseEnrolled: true, status: 'Completed', sectionid: req.sectionid,usertype:req.usertype }, { headers: this.headers }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }

  saveSelectedAns(req) {
    let headers = new HttpHeaders()
   
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
    return new Promise((resolve, reject) => {
      this.http.post(LMSAPIURL + 'lmsaddupdateuserresponse', req, { headers: headers }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }
  lmsgetallcourseinfo(){
    return new Promise((resolve,reject)=>{
      this.http.get(LMSAPIURL+'lmsgetallcourseinfo',{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  getLevelList(){
    return new Promise((resolve,reject)=>{
      this.http.get(LMSAPIURL+'getcourselevel',{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  saveActivityAnswer(formData){
    let headers = new HttpHeaders()
   
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
    .set('SessionToken',  this.sessionToken);
    return new Promise((resolve, reject) => {
      console.log("formData "+formData);
      this.http.post(LMSAPIURL + 'lmsaddupdateuseractivityresponse',  formData , { headers: headers }).subscribe(res => {
        resolve(res)
      }, error => {
        reject(error)
      })
    })
  }

  // getCourseDetails(req){
  //   return new Promise((resolve,reject)=>{
  //     this.http.post(LMSAPIURL+'lmsaddupdateuserleveltype',req,{headers:this.headers}).subscribe(res=>{
  //       resolve(res)
  //     },error=>{
  //       reject(error)
  //     })
  //   })
  // }

  getStudentcourseinfo(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'getlmsusertrackingdetail',req,{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  postUserCatnLevel(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'lmsaddupdateuserleveltype',req,{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  getUpdatedPerOfCourses(req){
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'updatecoursepercentage',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  updateActivityResponseStatus(rpgid,usertype){
    let req={RpgId:rpgid,usertype}
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'updateactivitysectionstatus',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  updateMCQResponseStatus(rpgid,usertype){
    let req={RpgId:rpgid,usertype}
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'updatemcqsectionstatus',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }


  updateUserPercentage(rpgid,usertype){
    let req={RpgId:rpgid,usertype}
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'lmsupdateuserpercentage',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  getGameStatus(rpgid,sectionId,usertype){
    let req={RpgId:rpgid,sectionid:sectionId,usertype}
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'lmsgetgamesectionstatus',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }

  updateGameStatus(rpgid,usertype?){
    let req={RpgId:rpgid,usertype}
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'updategamesectionstatus',req,{headers:this.headers,responseType: 'text'}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    })
  }


  getRecentCourse(rpgid,usertype){
    let req={RpgId:rpgid,usertype}

    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+"lmsgetpreviouscoursedetail",req).subscribe((res)=>{
        resolve(res);
      })
    })
  }


  //Teacher LMS

  teacherLMSGetAllCourseInfo(){
    return new Promise((resolve,reject)=>{
      this.http.get(LMSAPIURL+'teacherlmsgetallcourseinfo',{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  getTeacherLevelList(){
    return new Promise((resolve,reject)=>{
      this.http.get(LMSAPIURL+'getlmsteacherlevel',{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  gettypecourse(moduleName){
    let req = {
      "usertype":"teacher",
      courselevel:moduleName
    }
    return new Promise((resolve,reject)=>{
      this.http.post(LMSAPIURL+'gettypecourse',req,{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  getSubCategories(categoryId,AdminId){
    return new Promise((resolve,reject)=>{
      this.http.get(apiUrl+'SubCategoryDetails/GetSubCategories?AdminId='+AdminId+'&CategoryId='+categoryId,{headers:this.headers}).subscribe(res=>{
        resolve(res)
      },error=>{
        reject(error)
      })
    }) 
  }

  getYoutubeURL(url){
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? "https://www.youtube.com/embed/"+match[2]
      : null;
  }

  publishSomeData(data: any) {
    this.events.next(data);
}

getObservable(): Subject<any> {
    return this.events;
}

downloadPDF(url){
  let headers11 = new HttpHeaders()

  .set('Access-Control-Allow-Origin', '*')
  .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
  .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT')
  
  return this.http.get(url,{responseType:"arraybuffer",headers:headers11})
}

}
